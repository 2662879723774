.services-portfolio-height.market {
    border-radius: 35px 35px 0 0;
    background-image: linear-gradient(rgba(0, 29, 61, 0.6), rgba(0, 53, 102, 0.6)), url('../../../../public/img/openart-image_tgGEYXZ4_1715810954252_raw%201.jpg');
}

.services-portfolio-height.development {
    background-image: linear-gradient(rgba(0, 29, 61, 0.6), rgba(0, 53, 102, 0.6)), url('../../../../public/img/business-person-futuristic-business-environment%201.jpg');
}

.services-portfolio-height.transformation {
    background-image: linear-gradient(rgba(0, 29, 61, 0.6), rgba(0, 53, 102, 0.6)), url('../../../../public/img/openart-image_ssvcndG3_1715872345977_raw%201.jpg');
}

.services-portfolio-height.roadmap {
    background-image: linear-gradient(rgba(0, 29, 61, 0.6), rgba(0, 53, 102, 0.6)), url('../../../../public/img/communication-network-traffic-light-highway%201.jpg');
}

.services-portfolio-height.consulting {
    background-image: linear-gradient(rgba(0, 29, 61, 0.6), rgba(0, 53, 102, 0.6)), url('../../../../public/img/openart-image_2AkErhgH_1715873940524_raw%201.jpg');
    border-radius: 0 0 35px 35px;

}

.outsourcingBenefits {
    margin-bottom: 50px;
}

.outsourcingBenefits .title {
    font-family: Manrope;
    font-size: 38px;
    font-weight: 700;
    line-height: 51.91px;
    text-align: center;
    margin-bottom: 50px;
}

.outsourcingBenefits .cardsContainer {
    display: flex;
    max-width: 1100px;
    margin: auto;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
}

.outsourcingBenefits .benfitContainer {
    box-shadow: 4px 4px 15px 0px hsla(0, 0%, 0%, 0.25);
    padding: 15px;
    width: 330px;
    height: 165px;
    border-radius: 10px;
}

.outsourcingBenefits .imgCardBenefit {
    background-color: var(--color-primary);
    padding: 5px;
    border-radius: 50%;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
}

.outsourcingBenefits p {
    font-family: Manrope;
    font-size: 20px;
    font-weight: 700;
    line-height: 27.32px;
    margin: 0;
    margin-top: 10px;
    text-align: left;
    color: var(--color-primary);
}

.outsourcingMaincontainer .services-portfolio-height.second h1.titleBanner{
    text-align: right;
}



@media (max-width: 768px) {
    .contractingMaincontainer .benefitsSection .roofsContainer .targetComponentContainer,
    .contractingMaincontainer .benefitsSection .roofsContainer .targetComponentContainer.second{
        width: 90%;
        flex-direction: column;
    }

    .contractingMaincontainer .benefitsSection .roofsContainer .targetComponentContainer img,
    .contractingMaincontainer .benefitsSection .roofsContainer .targetComponentContainer .mainText{
        width: 100%;
    }
}