.contact-us-container {
    position: relative;
    height: auto;
    width: 100%;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    width: 100%;
    justify-content: space-around;
    padding: 30px 0;
}

.contact-us-total {
    display: flex;
    align-items: center;
    margin: 30px;
    justify-content: center;
    gap: 18rem;
}

.contact-us-set {
    justify-content: start;
    align-items: center;
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.contact-us-countries,
.contact-us-social {
    color: #000;
    z-index: 1000;
    height: 187px;
    width: 100%;
    border-radius: 18px;
    border-style: solid;
    border-width: 3px;
    border-color: var(--button-defaul-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-bottom: 60px;
    padding: 0 20px;
}

.contact-us-countries h3,
.contact-us-countries h4,
.contact-us-countries p {
    text-align: center;
    margin: 0;
    padding: 0;
}

.contact-us-countries h3 {
    font-family: Roboto;
    font-size: 25px;
    font-weight: 700;
    text-align: left;
    color: #000;
}

.contact-us-countries h4 {
    font-family: Roboto;
    font-size: 25px;
    font-weight: 500;
    text-align: left;
    color: #000;

}

.contact-us-countries p {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    color: #000;
}

.contact-us-logos {
    display: flex;
    justify-content: center;
    width: 211px;
    height: 37px;
    gap: 50px;
}

.contact-us-forms {
    min-width: 661px;
    display: inline-flex;
    border-radius: 17px;
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
    gap: 20px;
    background-color: rgba(255, 255, 255, 0.6);
    box-sizing: revert;
}

.contact-us-forms form {
    width: 700px;
}

.input-width,
.mbsc-textfield.mbsc-textfield-tags.mbsc-textarea {
    width: 100%;
    border-radius: 14px;
    border: 2px solid #001D3D;
    padding: 13px 24px;
}

.mbsc-ios.mbsc-form-control-wrapper.mbsc-ltr:after,
.mbsc-ios.mbsc-form-control-wrapper.mbsc-ltr:before {
    border: none;
}

.mbsc-ios.mbsc-textfield-wrapper-underline {
    padding: 0;
}

.mbsc-ios.mbsc-select-icon.mbsc-ltr {
    top: 14px;
    color: #001D3D;
}

textarea.input-width {
    height: 150px;
}

.input-width:focus-visible {
    border: 1px solid #0075BF !important;
}

.contact-us-forms form p {
    color: var(--color-secondary);
    font-weight: 600;
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    margin: 20px 0 8px;
    margin-left: 8px;
}

.contact-us-forms form button {
    width: 100%;
    height: 54px;
    padding: 16px 28px;
    margin-top: 30px;
    background-color: var(--button-defaul-color);
    color: var(--color-secondary);
    font-weight: bold;
    border: none;
    border-radius: 16px;
}

.contact-us-forms form button.sendFormConatct:hover {
    background: linear-gradient(#FFD60A, #FBBC05);
}

.contact-us-forms form button.sendFormConatct:disabled {
    background: linear-gradient(#ffd60a, #fbbd05);
    color: #939094;
    cursor: no-drop;
}

.phoneContactContainer {
    display: flex;
    align-items: center;
    gap: 15px;
    width: 100%;
}



.phoneContactContainer .input-width:focus-visible {
    border: 2px solid #0075BF !important;
}

.phoneContactContainer .phoneContactDropdown {
    width: min-content;
    display: flex;
    flex-direction: column;
    position: relative;
}

.phoneContactContainer .phoneContactDropdown img {
    width: min-content;
}

.phoneContactContainer .phoneContactDropdown .phoneContactOptions,
.mbsc-scroller-pointer .mbsc-scroller-wheel-wrapper-0:last-child {
    background-color: #fff;
    border-radius: 16px;
    border: 1px solid rgba(6, 214, 160, 1);
    width: 106px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    position: absolute;
    top: 48px;
    z-index: 99;
}

.mbsc-scroller-pointer .mbsc-scroller-wheel-wrapper-0:last-child {
    position: initial;
    width: 100% !important;
    border: none;
}

.phoneContactContainer .phoneContactDropdown .phoneContactOptions p {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 700;
    color: rgba(0, 7, 14, 1);
    font-size: 14px;
    width: 100%;
    cursor: pointer;
    margin: 0;
    padding: 5px 0;
}

.phoneContactContainer .phoneContactInputContainer {
    display: flex;
    position: relative;
}

.phoneContactContainer .phoneContactInputContainer img {
    position: absolute;
    left: 100px;
    top: 21px;
    color: #FBBC05;
}

.phoneContactContainer .phoneContactDropdown .phoneContactOptions p:not(:last-child),
.mbsc-scroller-pointer .mbsc-scroller-wheel-item:not(:last-child):not([aria-hidden="true"]) {
    border-bottom: 1px solid rgba(6, 214, 160, 1);
    color: #000;
    border-radius: 0;
}

.mbsc-scroller-pointer .mbsc-scroller-wheel-item.mbsc-selected {
    color: rgba(6, 214, 160, 1) !important;
}

.mbsc-form-control-wrapper:after,
.mbsc-form-control-wrapper:before {
    border-color: rgba(0, 0, 0, 0) !important;
}

.mbsc-flex-1-1.mbsc-popup-content {
    border: 1px solid rgba(6, 214, 160, 1) !important;
    background-color: #ffffff !important;
    border-radius: 16px !important;
}

.mbsc-ios.mbsc-picker .mbsc-popup-body,
.mbsc-ios-dark.mbsc-scroller-pointer.mbsc-scroller {
    background: #00000000 !important;
    border-radius: 16px !important;
}

.workWithUsFormMainContainer .phoneContactDropdown.phoneCode .phoneContactOptions {
    width: 100%;
    min-width: 190px;
}

.workWithUsFormMainContainer .phoneContactContainer.services .dropdownsContainer .phoneContactDropdown .phoneContactOptions {
    top: 58px;
}

.workWithUsFormMainContainer .phoneContactContainer.expert .phoneContactDropdown .phoneContactOptions {
    top: 111px;
}

/* 
Esta es la clase que esta fallando con respecto al form */
.phoneContactContainer .inputComponentContainer {
    width: 100%;
    display: flex;
}

.phoneContactContainer .phoneContactDropdown input {
    border-radius: 14px;
    border: 2px solid hsla(211, 100%, 12%, 1);
    cursor: pointer;
    padding: 12px;
    width: 125px;
}

.inputComponentContainer {
    width: 100%;
}

.phoneContactOptions .optionsContainer {
    max-height: 106px;
    overflow: auto;
    scrollbar-width: 1px;
    scrollbar-color: rgb(95, 95, 95);
}

::-webkit-scrollbar {
    width: 1px;
    /* Cambia el valor según lo que necesites */
}

::-webkit-scrollbar-thumb {
    background-color: rgb(95, 95, 95);
    /* Color del pulgar */
    border-radius: 6px;
    /* Radio de borde del pulgar */
}


.phone_container {
    width: 100%;
    gap: 8px;
    display: flex;
}

.input-width-domain {
    width: 10%;
    border-radius: 14px;
    border: none;
    display: flex;

}

#phone {
    width: calc(90% - 82px);
}

.alert.alert-danger.p-2.mt-2 {
    width: 622px;
}

.contact-us-container .contact-us-forms.contract {
    margin-left: 0;
}

.termsAndConditionsContainer {
    max-width: 100%;
    gap: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.termsAndConditionsContainer input {
    width: 20px;
    height: 20px;
    border-radius: 8px;
    border: 1px solid #605D62;
}

.termsAndConditionsContainer .termsAndConditionsText {
    font-size: 16px;
    color: #000000;
    width: 100%;
    margin: 0;
}

.termsAndConditionsContainer .termsAndConditionsText a {
    color: #0075BF;
}

.alert.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    margin-top: 10px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
    font-size: 14px;
    padding: 10px;
    border-radius: 8px;
}

.wsp-contact-button {
    background: linear-gradient(270deg, #FFDD36 -9.18%, #FFA901 109.18%);
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.3);
    top: 509px;
    justify-content: center;
    left: 287px;
    padding: 10px 12px;
    border-radius: 16px;
    gap: 8px;
    border: none;
    display: flex;
    align-items: center;
    font-family: "Manrope", sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
}

.workWithUsBanner {
    width: 100vw;
    margin-top: 80px;
    height: 40vh;
    background-image: url("../../public/img/openart-image_lSgZzj1D_1715794113833_raw Bigger 1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.workWithUsFormMainContainer.wwu {
    width: 100%;
    max-width: 1400px;
}

.workWithUsFormMainContainer.wwu h1.title {
    margin-top: 80px;
    margin-bottom: 35px;
}

.workWithUsFormMainContainer {
    min-height: calc(880px - 339px - 59px);
}

.workWithUsFormMainContainer .contact-us-forms {
    min-width: 1100px;
    max-width: 806px;
    margin: auto;
}

.workWithUsFormMainContainer .contact-us-forms .form-title {
    margin-bottom: 0;
}

.workWithUsFormMainContainer .contact-us-forms form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.workWithUsFormMainContainer .contact-us-forms a {
    color: #0075BF;
    font-weight: 700;
}

.workWithUsFormMainContainer .cvTextDiv {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.workWithUsFormMainContainer ul {
    display: none;
}

.workWithUsFormMainContainer ul.active {
    display: block;
    margin-top: 20px;
}

.workWithUsFormMainContainer .contact-us-forms button.sendFormConatct {
    width: 100%;
}


.workWithUsFormMainContainer .benefits-container .contact-us-forms button.sendFormConatct {
    margin: 0;
}


.workWithUsFormMainContainer .contact-us-forms form button {
    margin-top: 20px;
    max-width: 100%;
}

.workWithUsFormMainContainer .phoneContactDropdown,
.workWithUsFormMainContainer .phoneContactDropdown .inputNumber1,
.workWithUsFormMainContainer .phoneContactDropdown .phoneContactOptions,
.workWithUsFormMainContainer .phoneContactDropdown .phoneContactOptions .optionsContainer,
.workWithUsFormMainContainer .phoneContactDropdown .inputNumber2 {
    width: 100%;
}

.workWithUsFormMainContainer .phoneContactContainer .phoneContactDropdown .phoneContactInputContainer img {
    left: calc(100% - 30px);
}

.workWithUsFormMainContainer .phoneContactContainer .phoneContactDropdown .phoneContactInputContainer.skills img {
    left: calc(100% - 65px);
}

.workWithUsFormMainContainer .contact-us-forms form p.adviceCV {
    color: var(--color-secondary);
    font-weight: 400;
    font-size: 12px;
    font-family: 'Inter', sans-serif;
    margin: 0;
}

.workWithUsFormMainContainer .nameContainer,
.workWithUsFormMainContainer .phoneEmailContainer,
.workWithUsFormMainContainer .departamentCityContainer,
.workWithUsFormMainContainer .AddressRemoteContainer {
    display: flex;
    gap: 30px;
}

.workWithUsFormMainContainer .phoneContactContainer.phoneCode {
    align-items: flex-end;
}

.workWithUsFormMainContainer .phoneContactDropdown.phoneCode {
    width: 40%;
}

.workWithUsFormMainContainer .phoneContactDropdown.phoneCode p,
.workWithUsFormMainContainer .phoneContactDropdown.phoneCode input,
.errorContainer,
.workWithUsFormMainContainer .phoneEmailContainer .phoneContactContainerMain {
    width: 100%;
}

.workWithUsFormMainContainer input#file-upload-button {
    background-color: red;
}

.workWithUsFormMainContainer .uploadFileContainer {
    display: flex;
    align-items: flex-start;
    gap: 30px;
}

.workWithUsFormMainContainer .uploadFileContainer p {
    margin: 0;
    font-family: Manrope;
    font-size: 13px;
    font-weight: 700;
    line-height: 17.76px;
    text-align: left;
    color: #0F81FF;
}

.workWithUsFormMainContainer .uploadFileContainer .button {
    width: 204px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 39px;
    margin: 0;
}

.workWithUsFormMainContainer ul {
    list-style: none;
    margin-top: 20px;
}

.workWithUsFormMainContainer .phoneContactOptions .optionsContainer {
    max-height: 200px;
}

.workWithUsFormMainContainer .phoneContactDropdown.otherOptionInputContainer {
    margin-top: 15px;
}

.workWithUsFormMainContainer .phoneContactDropdown.otherOptionInputContainer .servicesDropdownContainer.otherOptionInputButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: 48px;
}

.workWithUsFormMainContainer .phoneContactDropdown.otherOptionInputContainer .servicesDropdownContainer.otherOptionInputButtonContainer button {
    margin: 0;
    height: 35px;
    width: 80px;
    padding: 0;
}


.workWithUsFormMainContainer .workWithUsContentContainer {
    padding: 0 20px;
}

.workWithUsFormMainContainer .workWithUsContentContainer .bannerWorkWithUS {
    background: linear-gradient(rgba(0, 29, 61, 0.4), rgba(0, 53, 102, 0.4)),
        url('../../public/img/woman-man-shake-hands-partners-made-deal-created-with-generative-ai-technology 3.jpg');
    width: 100%;
    max-width: 1220px;
    margin: auto;
    border-radius: 10px;
    height: 374px;
}

.workWithUsFormMainContainer .workWithUsContentContainer .textInfoWorkWithUS {
    width: 100%;
    margin: auto;
    margin-top: 65px;
    margin-bottom: 85px;
    max-width: 1012px;
}

@media (min-width: 300px) and (max-width: 768px) {

    .workWithUsFormMainContainer .phoneContactDropdown.phoneCode .phoneContactOptions {
        max-width: 100%;
    }

    .workWithUsFormMainContainer .workWithUsContentContainer .bannerWorkWithUS .max-container-services.r .titleBanner{
        text-align: center;
        width: 100%;
    }

    .workWithUsFormMainContainer .workWithUsContentContainer .bannerWorkWithUS {
        justify-content: center;
    }

    .mbsc-ios-dark.mbsc-scroller-wheel-item-3d.mbsc-wheel-item-multi {
        color: #000 !important;
    }

    .mbsc-scroller-wheel-item.mbsc-material-dark.mbsc-material.mbsc-ltr.mbsc-wheel-item-checkmark.mbsc-wheel-item-multi:not([aria-hidden="true"]) {
        border-bottom: 1px solid rgba(6, 214, 160, 1) !important;
    }

}

@media (min-width: 530px) and (max-width: 900px) {

    .contact-us-forms form {
        width: fit-content;
    }
}

/* MOBILE */
@media (min-width: 300px) and (max-width: 460px) {

    .phoneContactContainer {
        flex-direction: column;
    }

    .phoneContactContainer .phoneContactDropdown,
    .phoneContactContainer .phoneContactInputContainer,
    .phoneContactContainer .phoneContactDropdown input {
        width: 100%;
    }

    /* .phoneContactContainer .phoneContactDropdown input {
        width: 100px !important;
    } */

    .contact-us-forms form p {
        font-size: 15px;
    }

    .form-title {
        font-size: 25px;
    }

    .workWithUsFormMainContainer .nameContainer,
    .workWithUsFormMainContainer .phoneEmailContainer,
    .workWithUsFormMainContainer .departamentCityContainer,
    .workWithUsFormMainContainer .AddressRemoteContainer {
        gap: 0;
    }
}


@media (min-width: 200px) and (max-width: 1137px) {
    .contact-us-forms {
        min-width: 0;
    }

    .contact-us-forms form {
        width: fit-content;
    }

    .contact-us-total {
        flex-direction: column;
        gap: 7rem;
    }

    .contact-us-forms form button {
        width: 100%;
    }

    .termsAndConditionsContainer {
        width: 100%;
    }

    .phoneContactContainer .inputComponentContainer {
        width: 100%;
    }

    .inputComponentContainer {
        width: 100%;
    }

    .phoneContactContainer .inputComponentContainer {
        display: flex;
    }

    .phoneContactContainer .phoneContactInputContainer img {
        left: 80%
    }

    .contact-us-container {
        flex-direction: column;
    }

    .contract-us-set {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;
        height: auto;
        padding: 0 15px;
    }

    .contact-us-container .contact-us-forms.contract {
        margin-top: 30px;
    }
}

@media (min-width: 900px) and (max-width: 1400px) {

    .workWithUsFormMainContainer .workWithUsContentContainer .bannerWorkWithUS {
        padding-bottom: 91px;
        padding-left: 104px;
    }

    .workWithUsFormMainContainer .workWithUsContentContainer .bannerWorkWithUS .max-container-services.r {
        width: 100%;
        max-width: 373px;
    }

    .workWithUsFormMainContainer .benefitsCardsComponentSection .cardsContainer .benfitContainer {
        display: flex;
        gap: 10px;
    }

    .contact-us-forms {
        min-width: 0;
    }

    .contact-us-forms form {
        width: fit-content;
    }

    .contact-us-forms form button {
        width: 100%;
    }

    .termsAndConditionsContainer {
        width: 100%;
    }

    .phoneContactContainer .inputComponentContainer {
        display: flex;
    }

    .inputComponentContainer {
        width: 100%;
    }

    .contact-us-total {
        gap: 6rem;
    }
}

.form-title {
    text-align: center;
    color: var(--color-secondary);
}

/* Movil y Ipad */

@media (min-width: 200px) and (max-width: 792px) {
    .contact-us-forms form {
        width: 80%;
    }

    .workWithUsBanner {
        height: 20vh;
    }

}