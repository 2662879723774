.header-main-container {
  background-color: var(--color-primary);
  height: 80px;
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100vw;
  top: 0;
  z-index: 1000;
}

.header-container {
  display: flex;
  justify-content: space-between;
  width: 80%;
}

/* Otros estilos permanecen igual */


/* Otros estilos permanecen igual */


.header-home-container {
  display: flex;
  align-items: center;
  padding-top: 4px;

}

.header-contact-container {
  display: flex;
  gap: 40px;
  justify-self: end;
  align-items: center;
  margin-left: auto;
  margin-right: 10px;
}

.header-logos {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 24px;
  gap: 20px;
}

.header-logos img {
  cursor: pointer;
}

.img-5ig {
  width: 38px;
  height: 38px;
  cursor: pointer;
}

.logo-5ig {
  height: 55px;
  cursor: pointer;
}

.header-button {
  width: auto;
  height: 45px;
  border-radius: 30px;
  color: white;
  border: none;
  transition: ease-in 0.1s;
  letter-spacing: -1.46px;
  padding: 0 1.5rem;
  font-size: 20px;
  font-weight: 500;
}

.secondary-header-button {
  width: auto;
  color: white;
  border: none;
  transition: ease-in 0.1s;
  font-size: 20px;
  font-weight: 500;
  background: none;
  padding: 0;
}

.secondary-header-button a {
  width: auto;
  color: white;
  border: none;
  transition: ease-in 0.1s;
  font-size: 20px;
  font-weight: 500;
  background: none;
  padding: 0;
}

.secondary-header-button:hover,
.servicesContainer .ServicesNavbar p a:hover {
  color: var(--button-defaul-color);
}

.secondary-header-button.activeWindow {
  color: var(--button-defaul-color);
  border-bottom: 1px solid var(--button-defaul-color);
  border-radius: 0;
  padding: 0;
  height: fit-content;
}

.secondary-header-button:hover a {
  color: var(--button-defaul-color);
}

.secondary-header-button.activeWindow a {
  color: var(--button-defaul-color);
  border-bottom: 1px solid var(--button-defaul-color);
  border-radius: 0;
  padding: 0;
  height: fit-content;
}

#contract {
  background-color: var(--button-defaul-color);
  color: #000814;
  font-size: 20px;
  font-weight: 500;
}

#contract:hover {
  background-color: var(--button-hover-color);
}

#contract:active {
  background-color: var(--button-pressed-color);
  color: #fff;
}

.header-button:hover {
  color: #FFC300;
  cursor: pointer;
}

a {
  cursor: pointer;
}

.header-contact-container_phone,
.menuHeaderPhone {
  display: none;
}

.servicesContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.servicesContainer .serviceTextContainer {
  display: flex;
  cursor: pointer;
  gap: 5px;
}

.servicesContainer .ServicesNavbar {
  width: max-content;
  top: 88px;
  padding: 10px 15px 10px 15px;
  gap: 5px;
  border-radius: 10px;
  position: absolute;
  background-color: var(--color-primary);
  box-shadow: 4px 4px 15px 0px #00000040;
  z-index: 999;
  display: none;
}

.servicesContainer .ServicesNavbar.active {
  display: flex;
}

.servicesContainer .ServicesNavbar.active .techServicesNavBarContainer {
  display: none;
}


.servicesContainer .ServicesNavbar.active .techServicesNavBarContainer.active {
  display: flex;
}

.servicesContainer .ServicesNavbar.active .headerDropdownLine {
  border: 1px solid rgba(6, 214, 160, 1);
  margin: 0 30px;
  height: 100%;
  width: 1px;
}

.servicesContainer .ServicesNavbar p {
  border-bottom: 1px solid rgb(255, 255, 255);
  margin: 0;
  padding: 10px 0;
}

.servicesContainer .ServicesNavbar p.techServicesContainer {
  border-bottom: 1px solid rgb(255, 255, 255);
  margin: 0;
  padding: 10px 0;
}

.servicesContainer .ServicesNavbar p.techServicesContainer.active {
  border-bottom: 1px solid rgb(255, 255, 255);
  margin: 0;
  padding: 10px 0;
}

.servicesContainer .ServicesNavbar p.techServicesContainer.active .serviceElement {
  color: rgba(6, 214, 160, 1);
}

.servicesContainer .ServicesNavbar .serviceElement.headerTitle {
  border-bottom: none;
  font-family: Manrope;
  font-size: 20px;
  font-weight: 700;
  line-height: 27.32px;
  text-align: left;
  color: rgba(6, 214, 160, 1);
}

.servicesContainer .TechServicesNavbar {
  width: max-content;
  max-width: 260px;
  top: 88px;
  margin-top: 10px;
  padding: 0px;
  gap: 5px;
  background-color: var(--color-primary);
  box-shadow: 4px 4px 15px 0px #00000040;
  z-index: 999;
}

.servicesContainer .techServicesContainer img {
  margin-left: 10px;
  margin-bottom: 2px;
  cursor: pointer;
}

.servicesContainer .TechServicesNavbar ul,
.servicesContainer .TechServicesNavbar li {
  padding: 0;
  margin: 0;
}

.servicesContainer .TechServicesNavbar li::marker {
  color: #fff;
}

.servicesContainer .TechServicesNavbar ul {
  padding-left: 20px;
}

.servicesContainer .TechServicesNavbar p {
  border-bottom: 1px solid rgb(255, 255, 255);
  margin: 0;
  padding: 10px 0;
}

.servicesContainer p {
  color: #fff;
}

.servicesContainer .ServicesNavbar p:last-child {
  border-bottom: 1px solid rgb(0, 0, 0);
}

.servicesContainer .ServicesNavbar p a {
  color: #fff;
  font-family: Manrope;
  font-size: 17px;
  font-weight: 700;
  line-height: 23.22px;
  text-align: left;
}

.servicesContainer .TechServicesNavbar ul:last-child {
  border-bottom: 1px solid rgb(0, 0, 0);
}

.servicesContainer .TechServicesNavbar p a {
  color: #fff;
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  text-align: left;
}


.techServicesNavBarContainer.active.mobile {
    display: none !important;
}

/*Portatil*/

@media (min-width: 1500px) {
  .header-container {
    width: 1400px;
    margin: auto;
  }
}

/*Tablet*/
@media (min-width: 700px) and (max-width: 900px) {}

/*Telefono*/
@media (min-width: 300px) and (max-width: 900px) {
  .techServicesNavBarContainer.active.desk {
    display: none !important;
  }

  .techServicesNavBarContainer.active.mobile .headerDropdownLine{
    display: none;
  }

  .servicesContainer .ServicesNavbar.active.About {
    top: 160px;
  }

  .techServicesNavBarContainer.active.mobile {
    display: flex !important;
  }

  .header-contact-container {
    display: none;
    position: relative;
  }

  .menuHeaderPhone {
    display: block;
    position: relative;
    z-index: 99;
    margin-top: 15px;
    height: 45px;
    cursor: pointer;
  }

  .header-contact-container_phone {
    display: flex;
    flex-direction: column;
    width: 18rem;
    background-color: #001D3D;
    justify-content: center;
    align-items: center;
    position: fixed;
    padding: 30px 0;
    z-index: 1000000;
    top: -260px;
    gap: 25px;
    border-radius: 10px;
    left: calc(50% - 9rem);
    transition: 0.5s;
  }

  .servicesContainer .ServicesNavbar {
    top: 130px;
    left: 0;
    width: 288px;
    justify-content: center;
    align-items: center;
  }

  .header-contact-container_phone #contract {
    height: 40px;
  }

  .header-contact-container_phone.active {
    top: 120px;
  }

  .blur-background.active {
    height: 100%;
    background-color: rgba(0, 8, 20, 0.294);
    position: fixed;
    width: 100vw;
    transition: 0.5s;
    z-index: 80;
  }

}