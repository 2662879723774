.contractingMaincontainer .bannerhireVMA{
    height: 500px;
    width: 100vw;
    background-image: url('../../../../public/img/two-doctors-white-coats-are-discussing-document-with-man-background 1.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
}

.contractingMaincontainer .ApplySection{
    margin-top: 85px;
}

.contractingMaincontainer .keyServicesSection{
    margin-top: 35px;
}

.contractingMaincontainer .benfitContainer{
    display: flex;   
    height:auto;
    gap: 20px;
    align-items: center;
}

.contractingMaincontainer .benfitContainer .imgCardBenefit{
    width: 45px;
    height: 45px;
}

.contractingMaincontainer .benfitContainer p{
    width: 73%;
    margin: 0;
}

.contractingMaincontainer .mainTitleComponentSection p.mainTextTitle{
    max-width: 50%;
}

.contractingMaincontainer .button{
    max-width: 478px;
}
/*Tablet*/
@media (min-width: 700px) and (max-width: 910px) {
    
    .contractingMaincontainer .mainTitleComponentSection p.mainTextTitle {
        max-width: 90%;
    }
    
}
/*Telefono*/
@media (min-width: 320px) and (max-width: 702px) {
    .contractingMaincontainer .mainTitleComponentSection p.mainTextTitle {
        max-width: 95%;
    }
    .contractingMaincontainer .button {
        max-width: 330px;
    }
}