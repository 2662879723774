.container-img {
    width: 100%;
    height: 775px;
}

.contract-us-container {
    display: flex;
    margin: 0;
    padding: 0;
}

.contract-us-set p {
    width: 100%;
    justify-content: justify;
    font: 'Inter';
    font-weight: 900;
    font-size: 40px;
    line-height: 54.46px;
    color: white;
    letter-spacing: -1.46px;
}

.contract-us-set button {
    width: auto;
    padding: 10px 60px;
}

.form-title {
    color: var(--color-secondary);
    font-family: Manrope;
    font-size: 28px;
    font-weight: 700;
    line-height: 38.25px;
    text-align: center;
    margin-top: 50px;
}

.contact-us-container .contact-us-container .contact-us-total .contact-us-set {
    display: none;
}

.contact-us-container .contact-us-container {
    background: none;
    width: fit-content;
}
/* MOBILE */
@media (min-width: 300px) and (max-width: 460px) {

    
    .form-title {
        font-size: 17px;
        margin-top: 0;
    }
}