.services-portfolio-height {
    color: #fff;
}

.services-portfolio-height.second {
    display: flex;
    align-items: flex-end;
}

.button.second {
    margin-left: 300px;
}

.services-portfolio-height.second .max-container-services.r {
    margin-right: 5%;
}

.services-portfolio-height.second .max-container-services.r .textBanner{
    text-align: right;
}

.services-portfolio-height h1.titleBanner {
    font-family: Manrope;
    font-size: 24px;
    font-weight: 700;
    margin: 0;
    text-transform: none;
    margin-bottom: 0;
    text-align: left;
}

.services-portfolio-height.second h1.titleBanner {
    text-align: right;
}

.services-portfolio-height h1.titleBanner2 {
    margin-top: 0;
}

.services-portfolio-height p.textBanner {
    font-family: Manrope;
    font-size: 17px;
    font-weight: 500;
    text-align: justify;
}
/*Telefono*/
@media (min-width: 300px) and (max-width: 700px) {
    .services-portfolio-height h1.titleBanner {
        font-size: 20px;
    }
    .services-portfolio-height p.textBanner {
        font-size: 13px;
      
    }
}