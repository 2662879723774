.workWithUsFormMainContainer .termsAndConditionsContainer p.termsAndConditionsText {
    font-family: Manrope;
    font-size: 17px;
    font-weight: 500;
    line-height: 23.22px;
    text-align: left;
}

.workWithUsFormMainContainer .contact-us-forms form .form-title {
    margin-bottom: 50px;
}

.formMaincontainer .mainImgConsultingApply{
    height: 35vh;
    width: 100vw;
    background-image: url("../../../../../public/img/two-businessmen-shaking-hands-successful-business-agreement-generated-by-artificial-intelligence 1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
/*Tablet*/
@media (min-width: 700px) and (max-width: 910px) {
    
    .formMaincontainer .mainImgConsultingApply{
        height: 20vh;
    }
    
}
/*Telefono*/
@media (min-width: 320px) and (max-width: 702px) {
    .formMaincontainer .mainImgConsultingApply{
        height: 20vh;
    }
}