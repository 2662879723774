.consultingMaincontainer {
    max-width: 1400px;
    margin: auto;
    margin-bottom: 100px;
}

.consultingMaincontainer section{
    margin-top: 85px;
}

.consultingMaincontainer .titleH2{
    margin-bottom: 50px;
}

.consultingMaincontainer h1.title {
    font-family: Manrope;
    font-size: 52px;
    font-weight: 700;
    text-align: center;
}

.consultingMaincontainer p.mainTextTitle {
    font-family: Manrope;
    font-size: 28px;
    font-weight: 400;
    margin: 30px auto;
    text-align: center;
}

.consultingMaincontainer .workWithUsFormMainContainer .contact-us-forms h2.form-title {
    font-family: Manrope;
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 30;
    text-align: center;
}

.consultingMaincontainer .roofsContainer {
    flex-direction: row;
}

.consultingMaincontainer .roofsContainer .targetComponentContainer {
    width: 1100px;
    display: flex;
    align-items: flex-start;
    gap: 20px;
}

.consultingMaincontainer .roofsContainer .targetComponentContainer.second {
    flex-direction: row-reverse;
}

.consultingMaincontainer .roofsContainer .targetComponentContainer img {
    width: 55%;
    border-radius: 10px;
}

.consultingMaincontainer .roofsContainer .targetComponentContainer .mainText {
    width: 55%;
    font-family: Manrope;
    font-size: 20px;
    font-weight: 400;
    margin: 0;
    line-height: 27.32px;
    text-align: left;
    color: #747474;
}

.consultingMaincontainer .positionsApplySection {
    margin-bottom: 100px;
}

.consultingMaincontainer .positionsApplySection .positionsApplyContainer {
    height: 163px;
}

.consultingMaincontainer .positionsApplySection .positionsApplyContainer .positionsApplyBackground {
    background-color: rgba(0, 29, 61, 0.4);
    position: relative;
    top: -166px;
    height: 163px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.consultingMaincontainer .positionsApplySection .positionsApplyContainer .positionsApplyBackground .positionsApplyText {
    font-family: Manrope;
    font-size: 24px;
    font-weight: 700;
    line-height: 32.78px;
    text-align: center;
    color: #fff;
    width: 160px;
}

@media (max-width: 768px) {
    .consultingMaincontainer .roofsContainer .targetComponentContainer,
    .consultingMaincontainer .roofsContainer .targetComponentContainer.second{
        width: 90%;
        flex-direction: column;
    }

    .consultingMaincontainer .roofsContainer .targetComponentContainer img,
    .consultingMaincontainer .roofsContainer .targetComponentContainer .mainText{
        width: 100%;
    }
}