/* portrait-people-hugging-each-other-honor-hugging-day-celebration 1 */
 
.mainGetHireVMAFormImg{
    width: 100vw;
    margin-top: 80px;
    height: 500px;
    background-image: url("../../../../../public/img/portrait-people-hugging-each-other-honor-hugging-day-celebration 1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}