.blurrBackground {
    height: 100%;
    background-color: rgba(0, 0, 0, 0.303);
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1;
}

.isFetchSuccessful {
    background-color: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 684px;
    height: 356px;
    padding: 10px 0px 0px 0px;
    border-radius: 10px;

}

.isFetchSuccessful button.button .link {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 700;
    color: rgba(0, 29, 61, 1);
}

.isFetchSuccessful .succesIcon {
    background-color: #06D6A0;
    padding: 10px;
    width: 62px;
    height: 62px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.isFetchSuccessful .titleH4Bold {
    font-family: Manrope;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
}

.isFetchSuccessful .textBlack {
    font-family: Manrope;
    font-size: 20px;
    font-weight: 400;
    line-height: 27.32px;
    text-align: center;
    color: #00070E;
}

.isFetchSuccessful .button {
    width: 328px;
    text-align: center;
    margin: 0;
    display: flex;
    height: 39px;
    align-items: center;
    justify-content: center;
}

.isFetchSuccessful .buttonFetch {
    height: fit-content;
}