.roofsContainer {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    justify-content: center;
    gap: 30px;
    flex-direction: c;
}

 .targetComponentContainer {
    width: 330px;
}

.roofsContainer .targetComponentContainer .mainText {
    font-family: Manrope;
    font-size: 15px;
    font-weight: 500;
    line-height: 20.49px;
    text-align: left;
}
