.EventCardsComponentSection .cardsContainer.singleCard {
    display: flex;
    justify-content: center;
}

.EventCardsComponentSection .eventContainer.singleCardContainer {
    width: 100%;
    max-width: 800px;
}

.EventCardsComponentSection .eventContainer.singleCardContainer .eventDescription .eventDetails {
    max-width: 100%;
}

.EventCardsComponentSection .cardsContainer.singleCard .eventContainer.singleCardContainer .button.eventButton {
    max-width: 100%;

}

.EventCardsComponentSection .liveIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 25px;
    height: 25px;
    z-index: 2;
}


.EventCardsComponentSection {
    margin-bottom: 20px;
}

.EventCardsComponentSection .title {
    font-family: Manrope;
    font-size: 38px;
    font-weight: 700;
    line-height: 51.91px;
    text-align: center;
    margin-bottom: 50px;
}


.EventCardsComponentSection .cardsContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    justify-items: center;
    max-width: 1100px;
    margin: auto;
}

.EventCardsComponentSection .eventContainer {
    position: relative;
    box-shadow: 4px 4px 15px 0px hsla(0, 0%, 0%, 0.25);
    padding: 15px;
    width: 110%;
    max-width: 360px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.EventCardsComponentSection .cardsContainer .eventContainer .eventTitle {
    margin: 0;
}

.EventCardsComponentSection .cardsContainer .eventContainer .eventTitle .titleText {
    display: flex;
    font-family: Manrope;
    font-size: 24px;
    font-weight: 700;
    line-height: 50.78px;
    max-width: 330px;
    word-break: break-word;
    white-space: normal;
    text-align: left;
    margin: 0 0 10px;
}

.EventCardsComponentSection .mainEventInfo {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    align-items: center;
}

.EventCardsComponentSection .topLeftCard {
    width: 100px;
    height: 106px;
    border-radius: 10px;
    background-color: #001D3D;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: Manrope;
    z-index: 1;
}


.EventCardsComponentSection .topLeftCard .topText {
    width: 36px;
    height: 38px;
    font-family: Manrope;
    font-size: 28px;
    font-weight: 700;
    line-height: 38.25px;
    text-align: center;
    color: white;
    margin-bottom: 0px;
    margin-top: 20px;
}

.EventCardsComponentSection .topLeftCard .lineWhite {
    width: 80px;
    height: 0px;
    border-top: 1px solid white;
    margin: 4px 0;
}

.EventCardsComponentSection .topLeftCard .bottomText {
    font-family: Manrope;
    font-size: 28px;
    font-weight: 700;
    line-height: 38.25px;
    text-align: center;
    color: #06D6A0;
    margin-top: 0px;
}



.EventCardsComponentSection p.eventDescription {
    font-family: Manrope;
    font-size: 15px;
    font-weight: 500;
    max-width: 330px;
    word-wrap: break-word;
    white-space: normal;
    line-height: 20.49px;
    text-align: left;
    margin: 0 0 15px;
    color: #525252;
}

.EventCardsComponentSection .eventContainer.singleCardContainer p.eventDescription {
    max-width: 100%;
}


.EventCardsComponentSection .eventDetails {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.EventCardsComponentSection .eventDetail {
    display: flex;
    align-items: center;
    gap: 10px;
}

.EventCardsComponentSection .eventLogo {
    width: 18px;
    height: 20px;
    opacity: 0.8;
}

.EventCardsComponentSection .eventDate {
    font-family: Manrope;
    font-size: 20px;
    font-weight: 400;
    line-height: 05.32px;
    text-align: left;
}

.EventCardsComponentSection .eventButton {
    margin-top: 10px;

}

.watchMoreButton {
    font-family: Manrope;
    font-size: 15px;
    font-weight: 700;
    line-height: 15px;
    text-align: center;
    width: 107px;
    height: 52px;
    background: transparent;
    border: none;
    cursor: pointer;
    color: #06D6A0;
    margin-top: 20px;
    display: block;
    padding: 0;
    outline: none;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}


.watchMoreButton:hover {
    text-decoration: underline;
}


@media (max-width: 1000px) {
    .EventCardsComponentSection .cardsContainer {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, auto);
    }
}


@media (max-width: 740px) {
    .EventCardsComponentSection .cardsContainer {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
    }
}
/*Tablet*/
@media (min-width: 700px) and (max-width: 910px) {
    
    .image-container{
        width: 90%;
    }
    .EventCardsComponentSection .cardsContainer.singleCard {
        width: 90vw;
    }
    .EventCardsComponentSection .cardsContainer {
        
        gap: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .EventCardsComponentSection .eventContainer {
        width: 45vw;
    }
    
}
/*Telefono*/
@media (min-width: 320px) and (max-width: 702px) {
    .image-container{
        width: 90%;
        height: 200px;
    }
    .image-container img{
        height: 200px;
    }
    .EventCardsComponentSection .cardsContainer.singleCard {
        width: 90vw;
    }
    .mainTitleComponentSection h1.title.small-title {
        font-size: 23px;
        margin-top: 10px;
    }
    
}