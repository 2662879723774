.workWithUsFormMainContainer .contact-us-forms form .phoneContactContainer.services {
    flex-direction: column;
    align-items: flex-start;
}

.workWithUsFormMainContainer .phoneContactContainer.services .buttonAddDropdown {
    color: hsla(164, 95%, 43%, 1);
    margin: 0;
    padding: 0;
    height: fit-content;
    background-color: #ffffff;
    width: fit-content;
    cursor: pointer;
}

.workWithUsFormMainContainer .phoneContactContainer.services .dropdownsContainer {
    width: 100%;
}

/* .workWithUsFormMainContainer .phoneContactContainer.services .dropdownsContainer .phoneContactDropdown .phoneContactInputContainer {
    margin-bottom: 20px;
} */

.workWithUsFormMainContainer .phoneContactContainer.services .dropdownsContainer .phoneContactDropdown .phoneContactInputContainer .inputNumber2:disabled {
    border: 1px solid rgba(170, 170, 170, 1);
    background-color: rgba(170, 170, 170, 1);
    color: #ffffff;
}

/* .workWithUsFormMainContainer.requestExpert .phoneContactContainer.services .dropdownsContainer .phoneContactDropdown .phoneContactOptions {
    top: 58px;
} */

.workWithUsFormMainContainer .phoneContactContainer.edit .phoneContactDropdown .phoneContactInputContainer img{
    left: auto;
    right: 12px;
    top: 18px;
}
/* 
.workWithUsFormMainContainer .phoneContactContainer.edit .phoneContactDropdown .phoneContactInputContainer.saved img{
    left: calc(100% - 12.5%);
} */

.workWithUsFormMainContainer .inputContainer{
    width: 100%;
    display: flex;
    position: relative;
}

.formMaincontainer .mainImgOutSorcingApply {
    height: 35vh;
    width: 100vw;
    background-image: url("../../../../../public/img/medium-shot-woman-working-as-lawyer 1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
/*Tablet*/
@media (min-width: 700px) and (max-width: 910px) {
    .formMaincontainer .mainImgOutSorcingApply {
        height: 25vh;
    }
    
}
/*Telefono*/
@media (min-width: 320px) and (max-width: 702px) {
   
    .formMaincontainer .mainImgOutSorcingApply {
        height: 20vh;
    }
}