.techServicesMainContainer {
  min-height: calc(100vh - 339px - 59px);
}

.services-container {
  margin: 0;
  padding: 0;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 100;
  display: flex;
  justify-content: center;
}

.services-title {
  position: absolute;
  color: white;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.25rem;
  width: 70%;
}

.first-child {
  top: 625px;
}

.second-child {
  top: 3050px;
}

.second-child .line_1 {
  width: 113px;
}

.second-child .line_2 {
  width: 562px;
}

.services-title .line_1,
.services-title .line_2 {
  top: 48.125rem;
  background-color: white;
  height: 0.125rem;
}

.services-title .line_1 {
  width: 7.4375rem;
}

.services-title .line_2 {
  width: 55.75rem;
}

.services-title h2 {
  font-size: 55px;
  line-height: 85px;
  letter-spacing: -1.46px;
  font-weight: 900px;
}

.services-portfolio {
  width: 90%;
  max-width: 1050PX;
  height: auto;
  border-radius: 35px;
  margin-bottom: 100px;
}

.services-portfolio-height {
  min-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-size: cover;
  padding-bottom: 36px;
  background-position: center;
  background-repeat: no-repeat;
}

.services-portfolio-height.ia{
  border-radius: 35px 35px 0px 0;
}

.ia {
  background-image: linear-gradient(rgba(0, 29, 61, 0.4), rgba(0, 53, 102, 0.4)),
    url("../../../public/img/DreamShaper_v7_artificial_intelligence_and_machine_learning_1 1.jpg");
}

.ia h2 {
  position: relative;
  margin: 0;
  padding: 0;
  gap: 40px;
  height: 178px;
  color: white;
  font-family: Manrope;
  font-size: 28px;
  font-weight: 700;
  line-height: 38.25px;
  text-align: left;

}

.rpa h2,
.cloud h2,
.bi h2,
.wdad h2 {
  margin: 0;
  padding: 0;
  gap: 40px;
  width: 348px;
  height: 178px;
  color: white;
  font-family: Manrope;
  font-size: 28px;
  font-weight: 700;
  line-height: 38.25px;
  text-align: left;

}

.max-container-services {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.l {
  margin-left: 5%;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width:60%;
  padding: 2px;
}

.r {
  max-width: 55%;
  padding: 8px;
}

.r h2 {
  width: 100%;
}

.max-container-services h2 {
  height: fit-content;
}

.max-container-services button {
  margin: 0;
  /* margin-top: 15px; */
  background-color: var(--button-defaul-color);
  color: var(--color-secondary);
  font-size: 14px;
  max-width: 244px;
  font-weight: bold;
  border-radius: 16px;
  border: none;
}

.rpa {
  background-image: linear-gradient(rgba(0, 29, 61, 0.4), rgba(0, 53, 102, 0.4)),
    url("../../../public/img/DreamShaper_v7_Robot_doing_repetitive_tasks_on_a_computer_serv_3 1.jpg");
}

.cloud {
  background-image: linear-gradient(rgba(0, 29, 61, 0.4), rgba(0, 53, 102, 0.4)),
    url("../../../public/img/DreamShaper_v7_cloud_server_helping_a_growing_company_2 1.jpg");
}

.bi {
  background-image: linear-gradient(rgba(0, 29, 61, 0.4), rgba(0, 53, 102, 0.4)),
    url("../../../public/img/DreamShaper_v7_Holographic_lady_showing_companies_dashboards_a_2 1.jpg");
}

.wdad{
  background-image: linear-gradient(rgba(0, 29, 61, 0.4), rgba(0, 53, 102, 0.4)),
    url("../../../public/img/DreamShaper_v7_programmer_creating_a_new_app_from_a_big_compan_3 1.jpg");
  border-radius: 0 0 35px 35px;
}

.web-app-dev {
  background-image: linear-gradient(rgba(0, 29, 61, 0.4), rgba(0, 53, 102, 0.4)),
    url("../../../public/img/DreamShaper_v7_programmer_creating_a_new_app_from_a_big_compan_3 1.jpg");
}

.second-child h2 {
  width: 630.39px;

  font-size: 2.5rem;
  font-weight: 7px;
  padding: 0;
  margin: 0;
}

.container-about-us-grid {
  position: absolute;
  width: 70%;
  height: 457px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  top: 3187px;
  gap: 1.3%;
  text-align: center;
}

.about-us {
  width: 100%;
  height: 457px;
  border-radius: 42px;
  /* background-image: url("../../../public../../../public/img/about-us.webp"); */
  background-repeat: no-repeat;
  gap: 40px;
}

.text-about-us {
  width: 100%;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(1, 1fr);
  text-align: center;
}

.text-about-us h3,
.text-about-us p {
  width: 100%;
  margin: 0 auto;
}

.text-about-us p,
.text-about-us h3 {
  color: white;
}

.text-about-us p,
.text-about-us h3,
.button-contact-us {
  margin-top: 25px;
}

.text-about-us a {
  width: 60%;
}

.button-contact-us {
  display: flex;
  justify-content: center;
  width: 421px;
  height: 52px;
  background-color: var(--button-defaul-color);
  color: var(--color-secondary);
  border: none;
  padding: 13px 28px;
  border-radius: 16px;
  cursor: pointer;
  margin: auto;
  margin-top: 20px;
  gap: 8px;
  font-weight: bold;
  background-repeat: no-repeat;
}

.button-contact-us .img_link {
  /* background-image: url("../../../public../../../public/img/linkedin2.webp"); */
  width: 31px;
  height: 24px;
  background-repeat: no-repeat;
}

button {
  cursor: pointer;
}

a {
  text-decoration: none;
}

.button-contact-us p {
  margin: 0;
  text-align: center;
  align-self: center;
  color: #000814;
}

.button-contact-us:hover {
  background: linear-gradient(#ffd60a, #fbbc05);
}

.title {
  text-align: center;
}

.techServicesMainContainer .title:first-child {
  font-family: Manrope;
  font-size: 72px;
  font-weight: 700;
  text-align: center;
}

.techServicesMainContainer .title:nth-child(2) {
  font-family: Manrope;
  font-size: 38px;
  font-weight: 700;
  margin: 30px 0;
  color: #000;
  text-align: center;
}

.techServicesMainContainer .title:nth-child(3) {
  font-family: Manrope;
  font-size: 38px;
  font-weight: 700;
  margin: 30px 0;
  line-height: 51.91px;
  text-align: center;
}

/*Portatil*/

@media (min-width: 900px) and (max-width: 1900px) {
  .r {
    left: 48.0591%;
    max-width: 47.63%;
  }

  .second-child .line_1 {
    width: 60px;
  }

  .second-child .line_2 {
    width: 260px;
  }

  .second-child h2 {
    font-size: 2.3rem;
  }

  .container-about-us-grid {
    gap: 3px;
  }

  .text-about-us {
    justify-self: center;
    text-align: justify;
    width: 65%;
  }

  .button-contact-us {
    width: auto;
    justify-self: center;
  }

  .title2 h1 {
    line-height: 3.8rem;
  }

  .ia h2 {
    width: 450px;
  }
}

/*Tablet*/
@media (min-width: 700px) and (max-width: 900px) {
  .r {
    left: 48.0591%;
    max-width: 50.63%;
  }

  .second-child h2 {
    font-size: 1.9rem;
    line-height: 8px;
  }

  .second-child .line_1 {
    width: 60px;
  }

  .second-child .line_2 {
    width: 260px;
  }

  .container-about-us-grid {
    gap: 5px;
    width: 85%;
  }

  .text-about-us {
    justify-self: center;
    text-align: justify;
    width: 80%;
  }

  .button-contact-us {
    width: auto;
    justify-self: center;
    margin-left: 0;
  }

  .title2 h1 {
    line-height: 3.1rem;
  }

  .max-container-services button {
    padding: 10px;
  }

  .ia h2 {
    width: 450px;
  }
  .l{
    max-width: 90%;
  }
}

@media (min-width: 701px) and (max-width: 721px) {
  .max-container-services button {
    padding: 10px;
  }

  .second-child h2 {
    font-size: 1.6rem;
  }

  .container-about-us-grid {
    width: 85%;
  }

  .text-about-us {
    width: 80%;
  }

  .max-container-services h2 {
    font-size: 22px;
  }

  .r {
    left: 43%;
  }
}

/*Telefono*/
@media (min-width: 300px) and (max-width: 700px) {
  .text-about-us {
    justify-self: center;
    text-align: justify;
    width: 85%;
  }

  .l,
  .r {
    align-self: center;
  }

  .r {
    left: 39.059%;
    max-width: 58%;
  }

  .l, .r {
    max-width: 100%;
    margin-left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 20px;
    gap: 0px;
    align-items: flex-start;
    width: 98%;
    margin: 20px;
  }

  .l button,
  .services-portfolio-height.second .max-container-services.r button.button {
    padding: 0 10px;
    font-size: 15px;
    margin-left: 0px;
    width: 100%;
  }

  .r h2,
  .l h2 {
    font-size: 1rem;
    line-height: 30px;
  }

  .container-about-us-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* Alinea los elementos flexibles en el centro */
    gap: 3px;
  }

  .text-about-us a {
    width: 30%;
  }

  .button-contact-us {
    align-self: center;
    margin: 0 auto;
    margin-top: 10px;
    width: 75%;
  }

  .second-child .line_1 {
    width: 10%;
    display: none;
  }

  .second-child .line_2 {
    width: 10%;
    display: none;
  }

  .second-child h2 {
    width: 64%;
    font-size: 55px;
    line-height: 1px;
    height: 0px;
  }

  .first-child .line_1,
  .first-child .line_2 {
    width: 10%;
  }

  .ia h2,
  .wdad h2 {
    width: 55.8vw;
  }

  .cloud h2 {
    width: auto;
  }

  .button-contact-us {
    align-self: center;
    margin: 0 auto;
    margin-top: 13px;
    width: 100%;
  }

  .button-contact-us p {
    font-size: 13px;
    margin: 0;
    text-align: center;
    align-self: center;
    color: #000814;
  }

  .button-contact-us .img_link {
    width: 35px;
    height: 24px;
  }

  .button-contact-us .img_link {
    /* background-image: url("../../../public../../../public/img/linkedin2.webp"); */
    width: 37px;
    height: 24px;
    background-repeat: no-repeat;
  }

  .title2 h1 {
    line-height: 2rem;
  }

  .second-child h2 {
    width: 100%;
    height: auto;
    line-height: 6vh;
  }

  .second-child {
    top: 3050px;
    width: 100%;
    text-align: center;
  }

  .services-title h2 {
    font-size: 1.6rem;
    line-height: 45px;
    letter-spacing: -1.46px;
    font-weight: 900px;
  }

  .title_button_container button.button.ia{
    margin-left: 0;
    width: 100%;
  }
  .services-portfolio-height{
    min-height: 30vh;
  }
  .techServicesMainContainer .title:first-child {
  
    font-size: 40px;
    
  }
  .techServicesMainContainer .title:nth-child(2) {
    font-size: 30px;
  }
  .max-container-services button {
    max-width: none;
  }
  .services-portfolio-height {
    padding-bottom:15px;
  }

}

@media (min-width: 250px) and (max-width: 375px) {
  .second-child h2 {
    width: 100%;
    line-height: 1px;
    height: auto;
  }

  .l button,
  .r button {
    font-size: 13px;
  }

  .second-child {
    top: 3050px;
    width: 100%;
    text-align: center;
  }

  .second-child .line_1,
  .second-child .line_2 {
    display: none;
  }

  .services-title .line_1,
  .services-title .line_2 {
    display: none;
  }

  .services-title h2 {
    font-size: 1.6rem;
    line-height: 45px;
    letter-spacing: -1.46px;
    font-weight: 900px;
  }

  .button-contact-us .img_link {
    /* background-image: url("../../../public../../../public/img/linkedin2.webp"); */
    width: 37px;
    height: 24px;
    background-repeat: no-repeat;
  }

  .text-about-us {
    text-align: justify;
    width: 90%;
  }

  .button-contact-us p {
    font-size: 13px;
    margin: 0;
    text-align: center;
    align-self: center;
    color: #000814;
  }
}