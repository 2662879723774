.investmentMainContainer section.invest {
  margin-top: 85px;
  margin-bottom: 30px;
}

.investmentMainContainer section.keyInvestment {
  margin-bottom: 100px;
  max-width: 1100px;
  margin: auto;
}

.investmentMainContainer section.help {
  margin-top: 85px;
}

.investmentMainContainer section.start {
  display: flex;
  flex-direction: column;
}

.investmentMainContainer .keyInvestment .positionsApplyContainer {
  height: 231px;
  max-width: 331px;
}

.investmentMainContainer .keyInvestment .positionsApplyContainer .positionsApplyBackground {
  background-color: rgba(0, 29, 61, 0.4);
  position: relative;
  top: -236px;
  padding: 20PX;
  height: 231px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.investmentMainContainer .keyInvestment .positionsApplyContainer .positionsApplyBackground .positionsApplyText {
  font-family: Manrope;
  font-size: 20px;
  font-weight: 700;
  line-height: 27.32px;
  text-align: center;
  color: #fff;
}

.investmentMainContainer .keyInvestment .positionsApplyContainer .positionsApplyBackground .positionsApplyTextSecondary {
  font-family: Manrope;
  font-size: 17px;
  font-weight: 500;
  line-height: 23.22px;
  text-align: left;
  color: #fff;
}

.investmentMainContainer .keyInvestment .titleH2 {
  margin-bottom: 25px;
}

.investmentMainContainer .targetComponentContainer .maintitle {
  font-family: Manrope;
  font-size: 20px;
  font-weight: 700;
  line-height: 27.32px;
  text-align: center;
  color: var(--color-primary);
}

.investmentMainContainer .targetComponentContainer .mainText {
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  line-height: 20.49px;
  text-align: left;
}

.investmentMainContainer .help .textHelp {
  font-family: Manrope;
  font-size: 24px;
  font-weight: 400;
  line-height: 32.78px;
  text-align: center;
  max-width: 862px;
  margin: 25px auto;
}

.investmentMainContainer .services-portfolio {
  margin: 0;
  margin-top: 25px;
}

.investmentMainContainer .services-portfolio .titleBanner {
  font-family: Manrope;
  font-size: 38px;
  width: calc(100% + 20px);
  max-width: 536px;
  font-weight: 700;
  line-height: 51.91px;
  text-align: left;
}

.investmentMainContainer .services-portfolio .textBanner {
  font-family: Manrope;
  font-size: 24px;
  font-weight: 400;
  line-height: 32.78px;
  text-align: left;
}

.investmentMainContainer .services-portfolio-height.second .max-container-services.r .textBanner{
  font-family: Manrope;
  font-size: 24px;
  font-weight: 400;
  line-height: 32.78px;
  text-align: left;
}

.investmentMainContainer .services-portfolio .services-portfolio-height.growing-economy {
  background-image: linear-gradient(rgba(0, 29, 61, 0.4), rgba(0, 53, 102, 0.4)),
    url("../../../public/img/photorealistic-money-with-plant\ 1.jpg");
  border-radius: 35px 35px 0 0;
}

.investmentMainContainer .services-portfolio .services-portfolio-height.strategic-location {
  background-image: linear-gradient(rgba(0, 29, 61, 0.4), rgba(0, 53, 102, 0.4)),
    url("../../../public/img/landscape-with-palm-trees-foreground-mountains-background 1.jpg");
}

.investmentMainContainer .services-portfolio .services-portfolio-height.business-friendly-environment {
  background-image: linear-gradient(rgba(0, 29, 61, 0.4), rgba(0, 53, 102, 0.4)),
    url("../../../public/img/openart-image_8EdwNsOe_1715969282870_raw 1.jpg");
  border-radius: 0 0 35px 35px;
}

.investmentMainContainer .start .title {
  font-family: Manrope;
  font-size: 38px;
  font-weight: 700;
  text-align: center;
}

.investmentMainContainer .start .text {
  font-family: Manrope;
  font-size: 24px;
  font-weight: 400;
  margin: 23px auto;
  line-height: 32.78px;
  text-align: center;
}

.investmentMainContainer .start .textBlack {
  font-family: Manrope;
  font-size: 24px;
  margin: 0;
  margin-bottom: 70px;
  font-weight: 700;
  line-height: 32.78px;
  text-align: center;
}

.investmentMainContainer .r {
  max-width: 551px;
  display: flex;
  flex-direction: column;
  gap: 0;
  align-items: flex-end;
  padding: 8px;
}

.investmentMainContainer .growing-economy .r .titleBanner {
  text-align: left;
}

@media (max-width: 768px) {
  .services-portfolio-height.second .max-container-services.r {
    max-width: 100%;
    margin: 20px 20px 20px 0 ;
    padding: 0;
  }

  .investmentMainContainer .growing-economy .r .titleBanner,
  .services-portfolio-height.second .max-container-services.r .textBanner,
  .services-portfolio-height.second h1.titleBanner{
    text-align: justify;
    width: 95%;
  }
  

}
/*Telefono*/
@media (min-width: 300px) and (max-width: 700px) {
  .services-portfolio-height.second .max-container-services.r {
    margin: 20px;
    padding: 0 20px;
  }
}