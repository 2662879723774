/* ROLES YOU CAN GET SECTION*/

.contractingMaincontainer .rolesContractingSection {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.contractingMaincontainer .rolesContractingSection h1 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: Manrope;
    font-size: 38px;
    font-weight: 700;
    margin-top: 85px;
    text-align: left;

}

/* ROLES YOU CAN GET CONTAINER*/

.contractingMaincontainer .rolesContractingSection .ServicesContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    width: 80%;
    max-width: 1100px;
    margin: 0 auto;
    margin-top: 30px;
}

/* ROLES YOU CAN GET CARD*/

.contractingMaincontainer .rolesContractingSection .ServicesContainer .cardServiceContainer {
    width: 340px;
    box-shadow: 4px 4px 15px 0px #00000040;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 10px;
}

.contractingMaincontainer .rolesContractingSection .ServicesContainer .cardServiceContainer .cardTitleContainer {
    display: flex;
    align-items: center;
    gap: 10px;
}

.contractingMaincontainer .rolesContractingSection .ServicesContainer .cardServiceContainer .cardTitleContainer .imgCardService {
    background-color: var(--color-primary);
    padding: 5px;
    border-radius: 50%;
    width: 33px;
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* ROLES YOU CAN GET CARDS IMAGES STYLES */


.contractingMaincontainer .rolesContractingSection .ServicesContainer .positionsApplyContainer {
    height: 163px;
    width: 300px;
}

.contractingMaincontainer .rolesContractingSection .ServicesContainer .positionsApplyContainer .positionsApplyImgBackground {
    width: 100%;
}

.contractingMaincontainer .rolesContractingSection .ServicesContainer .positionsApplyContainer .positionsApplyBackground {
    background-color: rgba(0, 29, 61, 0.4);
    position: relative;
    top: -152px;
    height: 148px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contractingMaincontainer .rolesContractingSection .ServicesContainer .positionsApplyContainer .positionsApplyBackground .positionsApplyText {
    font-family: Manrope;
    font-size: 24px;
    font-weight: 700;
    line-height: 32.78px;
    text-align: center;
    color: #fff;
}

.contractingMaincontainer .rolesContractingSection .ServicesContainer .cardServiceContainer .button {
    width: 100%;
    border-radius: 16px;
}

/* ENDLESS BENEFITS SECTION */

.contractingMaincontainer .benefitsSection {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 50px;
}

.contractingMaincontainer .benefitsSection .roofsContainer {
    flex-direction: row;
    max-width: 1100px;
}

.contractingMaincontainer .benefitsSection .roofsContainer .targetComponentContainer {
    width: 1100px;
    display: flex;
    align-items: flex-start;
    gap: 20px;
}

.contractingMaincontainer .benefitsSection .roofsContainer .targetComponentContainer.second {
    flex-direction: row-reverse;
}

.contractingMaincontainer .benefitsSection .roofsContainer .targetComponentContainer img {
    width: 55%;
}

.contractingMaincontainer .benefitsSection .roofsContainer .targetComponentContainer .mainText {
    width: 55%;
    font-family: Manrope;
    font-size: 20px;
    font-weight: 400;
    line-height: 27.32px;
    text-align: left;
    color: #747474;
}

.contractingMaincontainer .benefitsSection .roofsContainer .targetComponentContainer .mainText h1 {
    font-family: Manrope;
    font-size: 28px;
    font-weight: 700;
    line-height: 38.25px;
    text-align: left;
    color: #001D3D;
    margin-bottom: 25px;
}

.contractingMaincontainer .benefitsSection .roofsContainer .targetComponentContainer .mainText p {
    font-family: Manrope;
    font-size: 20px;
    font-weight: 400;
    line-height: 27.32px;
    text-align: left;
    color: #525252;
}


/*Tablet*/
@media (min-width: 700px) and (max-width: 910px) {
    
    .contractorsMainContainer h1.title {
        font-size: 35px;        
    }
    .contractorsMainContainer p.textMainContractor {
        font-size: 25px;
        padding: 0 20px;
    }
    .contractingMaincontainer .rolesContractingSection h1 {
        font-size: 35px;
        margin-top: 50px;
    }
    .contractingMaincontainer .rolesContractingSection .ServicesContainer .cardServiceContainer {
        width: 38vw;
        height: 45vh;
    }
    .contractingMaincontainer .rolesContractingSection .ServicesContainer .positionsApplyContainer {
       
        width: 100%;
        
    }
    .positionsApplyContainer img {
        height: 90%;
    }
    .contractingMaincontainer .rolesContractingSection .ServicesContainer .positionsApplyContainer .positionsApplyBackground .positionsApplyText {
        padding: 0 5px;
    }
   
}
/*Telefono*/
@media (min-width: 320px) and (max-width: 702px) {
    .contractorsMainContainer h1.title {
        font-size: 25px;
        line-height: unset;        
    }
    .contractorsMainContainer p.textMainContractor {
        font-size: 20px;
        line-height: unset; 
        padding: 0 17px;
    }
    .contractingMaincontainer .rolesContractingSection h1 {
        font-size: 25px;
        margin-top: 50px;
    }
    .mainTextCardService{
        text-align: justify;
    }
    .contractingMaincontainer .benefitsSection .roofsContainer .targetComponentContainer .mainText p {
    
        text-align: justify;
    }
}