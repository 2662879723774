 .financialsMainContainer {
     padding: 0 20px;
 }

 .financialsMainContainer .financialsBanner {
     background-image: linear-gradient(rgba(0, 29, 61, 0.4), rgba(0, 53, 102, 0.4)), url('../../../../public/img/futuristic-finance-digital-market-graph-user-interface-with-diagram-technology-hud-graphic-concept 1.jpg');
     height: 450px;
     border-radius: 10px;
     min-height: 450px;
     display: flex;
     flex-direction: column;
     justify-content: flex-end;
     background-size: cover;
     padding-bottom: 115px;
     background-position: center;
     background-repeat: no-repeat;
 }

 .financialsMainContainer .mainTextTitle.top {
     margin-bottom: 84px;
 }

 .financialsMainContainer .financialsBanner .max-container-services.l {
     margin-left: 104px;
 }

 .financialsMainContainer .financialsBanner .max-container-services.l .button {
     margin-top: 35px;
 }

 .financialsMainContainer .services-portfolio {
     margin: 0 auto;
     width: 100%;
     max-width: 1220px;
 }

 .financialsMainContainer .mainTitleComponentSection h1.title {
     margin-bottom: 30px;
     width: 100%;
     max-width: 1220px;
 }

 .financialsMainContainer .financialsDescriptionContainer {
     display: flex;
     width: 100%;
     max-width: 1220px;
     gap: 24px;
     margin: auto;
     margin-top: 35px;
 }

 .financialsMainContainer .financialsDescriptionContainer .textContainer {
     display: flex;
     flex-direction: column;
     gap: 15px;
 }

 .financialsMainContainer .financialsDescriptionContainer p {
     text-align: justify;
 }

 .financialsMainContainer .problemsFeaturesContainer {
     display: flex;
     width: 100%;
     max-width: 1220px;
     margin: auto;
     margin-top: 35px;
     gap: 24px;
 }

 .financialsMainContainer .problemsFeaturesContainer .keyBeneffitsContainer {
     box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.25);
     border-radius: 10px;
     padding: 20px 15px;
 }

 .financialsMainContainer .cardsContainer {
     max-width: 1220px;
     gap: 24px;
 }

 .financialsMainContainer .cardsContainer .benfitContainer {
     background: rgba(0, 29, 61, 1);
     width: 390px;
     min-height: 179px;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
 }

 .financialsMainContainer .cardsContainer .benfitContainer .benefitTitle .titleText {
     color: rgba(6, 214, 160, 1);
     text-align: center;
 }

 .financialsMainContainer .cardsContainer .benfitContainer .benefitTitle .imgCardBenefit {
     background: rgba(6, 214, 160, 1);
     height: 69px;
     width: 69px;
 }

 .financialsMainContainer .cardsContainer .benfitContainer .benefitTitle {
     flex-direction: column;
     width: 100%;
     gap: 20px;
 }

 .financialsMainContainer .targetsSection {
     width: 100%;
     max-width: 1220px;
     margin-top: 35px;
 }

 .financialsMainContainer .targetsSection .targetsContainer {
     gap: 24px;
     margin-bottom: 35px;
 }

 .financialsMainContainer .targetsSection .targetsContainer .targetHomeContainer {
     height: auto;
     width: 287px;
     padding-top: 0;
     justify-content: flex-start;
 }

 .financialsMainContainer .targetsSection .targetsContainer .targetHomeContainer h2 {
     font-family: Manrope;
     font-size: 24px;
     width: 100%;
     max-width: 404px;
     margin: 15px auto;
     font-weight: 700;
     line-height: 23.22px;
     text-align: left;
 }

 .financialsMainContainer .targetsSection .targetsContainer .targetHomeContainer .mainText {
     font-family: Manrope;
     font-size: 17px;
     font-weight: 500;
     line-height: 23.22px;
     text-align: left;
 }

 .financialsMainContainer .targetsSection .targetsContainer .targetHomeContainer:nth-child(4) {
     background-color: #06D6A0;
 }

 .financialsMainContainer .targetsSection .targetsContainer .targetHomeContainer:nth-child(4),
 .financialsMainContainer .targetsSection .targetsContainer .targetHomeContainer:nth-child(4) h2 {
     color: #000000;
 }

 .financialsMainContainer .roofsContainer {
     flex-direction: column;
     width: 100%;
     margin-top: 35px;
     max-width: 1220px;
 }

 .financialsMainContainer .roofsContainer .targetComponentContainer {
     display: flex;
     flex-direction: row;
     align-items: center;
     width: 100%;
     gap: 24px;
 }

 .financialsMainContainer .roofsContainer .targetComponentContainer.second {
     flex-direction: row-reverse;
 }

 .financialsMainContainer .roofsContainer .targetComponentContainer img {
     width: 50%;
 }

 .financialsMainContainer .roofsContainer .targetComponentContainer .mainText {
     width: 50%;
     display: flex;
     flex-direction: column;
     gap: 24px;
 }

 .financialsMainContainer .roofsContainer .targetComponentContainer .mainText h1,
 .financialsMainContainer .roofsContainer .targetComponentContainer .mainText p {
     text-align: left;
 }


 .financialsMainContainer .buttonFinancials {
     margin-top: 35px;
 }

 .financialsMainContainer .keyServicesSection {
     margin-bottom: 35px;
 }

 .financialsMainContainer .benefitsCardsComponentSection .title {
    margin-bottom: 35px;
    margin-top: 85px;

 }


 @media (max-width: 700px) {

     .financialsMainContainer .targetsSection .targetsContainer .targetInfoContainer {
         height: auto;
     }


     .financialsMainContainer .financialsBanner .max-container-services.l {
        margin: 0 auto;
    }
 }

 @media (min-width: 900px) and (max-width: 1100px) {
    .financialsMainContainer .financialsDescriptionContainer {
        flex-direction: column;
    }
 }

 @media (min-width: 300px) and (max-width: 900px) {

    .financialsMainContainer .financialsDescriptionContainer,
    .financialsMainContainer .problemsFeaturesContainer,
    .financialsMainContainer .roofsContainer .targetComponentContainer,
    .financialsMainContainer .roofsContainer .targetComponentContainer.second{
        flex-direction: column;
    }

    .financialsMainContainer .roofsContainer .targetComponentContainer img,
    .financialsMainContainer .roofsContainer .targetComponentContainer .mainText {
        width: 100%;
    }
}