.banner-container {
  margin: 0;
  padding: 0;
  /* background-image: url("../components/background_banner.svg"); */
  height: 31rem;
  min-width: 100%;
  background-size:cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  position: relative;
}
.banner-title {
  margin-top: 9rem;
  margin-left: 9.375rem;
  position: relative;
}
.titles {
  position: relative;
  margin-bottom: 1.0625rem;
  font-weight: 900;
  text-align: left;
  width: 100%;
  height: 155px;
  font-weight: 900;
}
.title1 {
  width: auto;
  height: 3.2rem;
}
.title2 {
  width: auto;
  height: 5.3125rem;
}


.title1 h2{
  font-size: 4rem;
  line-height: 3.3125rem;
  letter-spacing: -0.09rem;
  text-align: left;
  
}
.title2 h1{
  font-size: 4.81rem;
  line-height: 5.3125rem;
  letter-spacing: -0.09rem;
  
}

.banner-title h1,
.banner-title h2 {
  margin: 0;
  padding: 0;
}
.banner-title h2 {
  color: white;
}
.banner-title h1 {
  color: var( --button-defaul-color);
}

.banner-buttons {
  display: flex;
}

.banner-buttons .yellow-button,
.banner-buttons .blue-button {
  border: none;
  border-radius: 1rem;
  width: 24.1875rem;
  height: 3.375rem;
  gap: 0.5rem;
  margin-right: 0.5rem;
  cursor: pointer;
  font-weight: bold;
}
.yellow-button {
  background-color: var( --button-defaul-color);
  color: var(--color-secondary);
}
.blue-button {
  background: linear-gradient(#0898f2, #003566);
  color: white;
}

.yellow-button:hover {
  background: linear-gradient(#ffd60a, #fbbc05);
}

.blue-button:hover {
  background: linear-gradient(#003566, #0898f2);
}



/*Portatil*/
 
@media (min-width: 910px) and (max-width: 1400px) {
  .title2 h1{
    font-size: 3.8rem;
  }

}
 
/*Tablet*/
@media (min-width: 700px) and (max-width: 910px) {
  .title2 h1{
    font-size: 3.8rem;
  }

  .banner-buttons{
    margin-top: 5rem;
  }
}
 
/*Telefono*/
@media (min-width: 320px) and (max-width: 702px) {
  .title2 h1{
    font-size: 2.2rem;
    line-height:4.3125rem;
  }
  .title1 h2{
    font-size: 2rem;
    line-height:4.3125rem;
  }
  .banner-buttons{
    margin-top: 5rem;
    width: 50%;
  }
  .banner-title{
    width: 80%;
    margin: 0 auto;
    align-self: center;
    text-align: center;
  }

  .second-child h2 {
    width: 135%;
    font-size: 1.2rem;
    line-height: 32px;
    height: 37px;
  }
  .r h2, .l h2 {
    font-size: 1rem;
    line-height: 30px;
}
.max-container-services button {
  padding: 10px;
}


  
}