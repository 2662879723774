.eventsMaincontainer .events_image {
    width: 100%;
    
    border-radius: 8px;
    object-fit: cover;
}

.eventsMaincontainer .overlay-text {
    position: absolute;
    bottom: 20%;
    left: 20%;
    color: white;
    font-family: 'Manrope', sans-serif;
    font-size: 30px;
    font-weight: 700;
    text-align: justify;
    pointer-events: none;
    max-width: 650px;
    line-height: 1.2;
    white-space: normal;
    z-index: 2;
}

.small-title {
    font-family: Manrope;
    font-size: 38px;
    font-weight: 700;
    line-height: 51.91px;
    text-align: center;
}

@media (min-width: 300px) and (max-width: 1000px) {

    .eventsMaincontainer .overlay-text {
        font-size: 20px;
        bottom: 20%;
        left: 20%;
        max-width: 50%;
        padding: 20px auto;
        text-align: justify;
    }
}

@media (min-width: 300px) and (max-width: 800px) {

    .eventsMaincontainer .overlay-text {
        font-size: 18px;
        bottom: 20%;
        left: 20%;
        max-width: 60%;
        padding: 20px auto;
        text-align: justify;
    }
}