.ECommerceMainContainer .services-portfolio-height.VMA {
    width: 1220px;
    background-image: linear-gradient(rgba(0, 29, 61, 0.4), rgba(0, 53, 102, 0.4)),
        url('../../../../public/img/photorealistic-scene-with-warehouse-logistics-operations 1.jpg');
    margin: 0 auto;
    height: 450px;
    gap: 0px;
    padding: 90px 50px;
    border-radius: 10px;
}

.ECommerceMainContainer .mainTitleComponentSection .title {
    max-width: 862px;
    margin-bottom: 60px;
}

.ECommerceMainContainer .ECommerceText {
    font-family: Manrope;
    font-size: 28px;
    font-weight: 400;
    line-height: 38.25px;
    text-align: center;
}

.ECommerceMainContainer .ECommerceText {
    width: 862px;
    margin: 35px auto;
}

.ECommerceMainContainer .benefitsCardsComponentSection .cardsContainer {
    max-width: 1400px;
    gap: 24px;
}

.ECommerceMainContainer .benefitsCardsComponentSection .benfitContainer {
    width: 391px;
}

.ECommerceMainContainer .targetComponentContainer {
    width: 391px;
}

.ECommerceMainContainer .stepsProcessSection,
.ECommerceMainContainer .keyFeaturesSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ECommerceMainContainer .targetComponentContainer .mainText .titleH3Bold {
    font-size: 24px;
}

.ECommerceMainContainer .targetComponentContainer .mainText .textBlack {
    margin: 15px 0 0 0;
}

.ECommerceMainContainer .stepsProcessSection .stepProcessContainer,
.ECommerceMainContainer .keyFeaturesSection .keyFeaturesContainer {
    display: flex;
    gap: 24px;
    max-width: 1400px;
    justify-content: center;
    flex-wrap: wrap;
}

.ECommerceMainContainer .stepProcessContainer .targetComponentContainer .mainText{
    margin-top: 10px;
}

.ECommerceMainContainer .keyFeaturesSection .keyFeaturesContainer .keyBenefitsContainer {
    width: 287px;
    height: 156px;
    box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 12px 15px 12px 15px;
    gap: 20px;
    border-radius: 10px;
   
}

.ECommerceMainContainer .keyFeaturesSection .keyFeaturesContainer .keyBenefitsContainer p {
    font-family: Manrope;
    font-size: 24px;
    font-weight: 700;
    line-height: 32.78px;
    text-align: center;
}

.ECommerceMainContainer .services-portfolio-height.VMA .titleBanner{
    text-transform: none;
}

.ECommerceMainContainer .services-portfolio-height.VMA .button{
    margin-top: 35px;
}

.ECommerceMainContainer .services-portfolio-height.VMA .l{
    max-width: 75%;
}
.Finalbtn{
    width: 100%;
    height: 10vh;
   
    display: flex;
    justify-content: center;
    align-items: end;
}
/*Tablet*/
@media (min-width: 700px) and (max-width: 910px) {
    
    .ECommerceMainContainer .services-portfolio-height.VMA {
        width: 90vw;
        height: 20vh;
    }
    .ECommerceMainContainer .ECommerceText {
        width: 90%;
        font-size: 20px;
        line-height: unset;
        margin-bottom: 0;
    }
    .ECommerceMainContainer .benefitsCardsComponentSection .benfitContainer {
        width: 46vw;
    }
    .ECommerceMainContainer .targetComponentContainer {
        width: 40vw;
    }
    .ECommerceMainContainer .targetComponentContainer img{
        width: 100%;
    }
    
}
/*Telefono*/
@media (min-width: 320px) and (max-width: 702px) {
    .ECommerceMainContainer .mainTitleComponentSection .title {
        padding: 20px;
        margin-bottom: 20px;
    }
   
    .ECommerceMainContainer .services-portfolio-height.VMA {
        width: 90vw;
        height: 30vh;
        min-height: unset;
        justify-content: center;
        align-items: center;
    }
    .ECommerceMainContainer .services-portfolio-height.VMA .l {
        max-width: unset;
        width: 80vw;
    }
    .ECommerceMainContainer .ECommerceText {
        width: 90%;
        font-size: 17px;
        line-height: unset;
        margin-bottom: 0;
    }
    .ECommerceMainContainer .benefitsCardsComponentSection .benfitContainer {
        width: 90vw;
    }
    .ECommerceMainContainer .targetComponentContainer {
        width: 85vw;
    }
    .ECommerceMainContainer .targetComponentContainer img{
        width: 100%;
    }
    .ECommerceMainContainer .targetComponentContainer .mainText .titleH3Bold {
        font-size: 20px;
    }
    .ECommerceMainContainer .targetComponentContainer .mainText .textBlack {
        font-size: 17px;
    }
    .ECommerceMainContainer .keyFeaturesSection .keyFeaturesContainer .keyBenefitsContainer p {
        font-size: 20px;
    }
    .ECommerceMainContainer .keyFeaturesSection .keyFeaturesContainer .keyBenefitsContainer {
        height: 94px;
    }
}
/* iphone SE */
@media (min-width: 320px) and (max-width: 400px) {
    .ECommerceMainContainer .services-portfolio-height.VMA {        
        height: 40vh;
    }
}