.ERPMainContainer .services-portfolio-height.VMA {
    width: 1220px;
    background-image: linear-gradient(rgba(0, 29, 61, 0.4), rgba(0, 53, 102, 0.4)),
        url('../../../../public/img/scene-with-business-person-working-futuristic-office-job 1.jpg');
    margin: 0 auto;
    height: 450px;
    gap: 0px;
    padding: 90px 50px;
    border-radius: 10px;
}

.ERPMainContainer .mainTitleComponentSection .title {
    max-width: 607px;
    margin-bottom: 60px;
}

.ERPMainContainer .ERPText {
    font-family: Manrope;
    font-size: 28px;
    font-weight: 400;
    line-height: 38.25px;
    text-align: center;
}

.ERPMainContainer .ERPText {
    width: 862px;
    margin: 35px auto;
}

.ERPMainContainer .benefitsCardsComponentSection .cardsContainer {
    max-width: 1400px;
    gap: 24px;
}

.ERPMainContainer .benefitsCardsComponentSection .benfitContainer {
    width: 391px;
}

.ERPMainContainer .targetComponentContainer {
    max-width: 287px;
}

.ERPMainContainer .stepsProcessSection,
.ERPMainContainer .keyFeaturesSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ERPMainContainer .targetComponentContainer .mainText .titleH3Bold {
    font-size: 24px;
}

.ERPMainContainer .targetComponentContainer .mainText .textBlack {
    margin: 15px 0 0 0;
}

.ERPMainContainer .stepsProcessSection .stepProcessContainer,
.ERPMainContainer .keyFeaturesSection .keyFeaturesContainer {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    justify-content: center;
}

.ERPMainContainer .keyFeaturesSection .keyFeaturesContainer .keyBeneffitsContainer {
    width: 287px;
    height: 156px;
    box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 12px 15px 12px 15px;
    gap: 20px;
    border-radius: 10px;

}

.ERPMainContainer .keyFeaturesSection .keyFeaturesContainer .keyBeneffitsContainer p {
    font-family: Manrope;
    font-size: 24px;
    font-weight: 700;
    line-height: 32.78px;
    text-align: center;
}

.ERPMainContainer .services-portfolio-height.VMA .titleBanner{
    text-transform: none;
}

.ERPMainContainer .services-portfolio-height.VMA .button{
    margin-top: 35px;
}

.ERPMainContainer .services-portfolio-height.VMA .l{
    max-width: 75%;
}
/*Tablet*/
@media (min-width: 700px) and (max-width: 910px) {
    .ERPMainContainer .services-portfolio-height.VMA {
        width: 90vw;
        height: 20vh;
        justify-content: center;
        align-items: center;
    }
    .ERPMainContainer .services-portfolio-height.VMA .l {
        max-width: unset;
        width: 80vw;
        
    }
    .ERPMainContainer .services-portfolio-height.VMA .titleBanner {
    
        font-size: 24px;
    }
    .ERPMainContainer .ERPText {
        width: 90%;
        font-size: 20px;
        line-height: unset;
        margin-bottom: 0;
    }
    .ERPMainContainer .benefitsCardsComponentSection .benfitContainer {
        width: 45vw;    
    }
    
    
}
/*Telefono*/
@media (min-width: 320px) and (max-width: 702px) {
   
    .ERPMainContainer .services-portfolio-height.VMA {
        width: 90vw;
        height: 30vh;
        min-height: unset;
        justify-content: center;
        align-items: center;
    }
    .ERPMainContainer .services-portfolio-height.VMA .l {
        max-width: unset;
        width: 85vw;
        
        
    }
    .ERPMainContainer .services-portfolio-height.VMA .titleBanner {
    
        font-size: 20px;
    }
    .ERPMainContainer .ERPText {
        width: 90%;
        font-size: 17px;
        line-height: unset;
        margin-bottom: 0;
    }
    .ERPMainContainer .benefitsCardsComponentSection .benfitContainer {
        width: 90vw;
    }
    .ERPMainContainer .targetComponentContainer .mainText .titleH3Bold {
        font-size: 20px;
    }
    .ERPMainContainer .targetComponentContainer .mainText .textBlack {
        font-size: 17px;
    }
    .ERPMainContainer .keyFeaturesSection .keyFeaturesContainer .keyBeneffitsContainer p {
        font-size: 20px;
    }
    .ERPMainContainer .keyFeaturesSection .keyFeaturesContainer .keyBeneffitsContainer {
        height: 94px;
    }
}
/* iphone SE */
@media (min-width: 320px) and (max-width: 400px) {
    .ERPMainContainer .services-portfolio-height.VMA {
        
        height: 40vh;
    }
}