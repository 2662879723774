
.language-switcher {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-left: auto;
    margin-right: 10px;
  }
  

  @media (max-width: 900px) {
    .language-switcher {
      margin-left: auto; 
    }
  }
  
 
  @media (min-width: 901px) {
    .language-switcher {
      margin-left: 30px; 
    }
  }
  
  .language-icon {
    width: 30px;
   
  }  