.mainImgFormTechnologiesApply {
    width: 100%;
    height: 400px;
    margin-top: 80px;
    background-image: url('../../../public/img/businessman-working-futuristic-office 1.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.workWithUsFormMainContainer .form-text {
    font-family: Manrope;
    font-size: 17px;
    font-weight: 500;
    line-height: 23.22px;
    text-align: center;
}

.workWithUsFormMainContainer {
    max-width: 686px;
    margin: auto;
    margin-top: 30px;
}

.workWithUsFormMainContainer .contact-us-forms {
    min-width: 686px
}

.blurrBackgruond {
    height: 100%;
    background-color: rgba(0, 0, 0, 0.303);
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1;
}

.isFetchSuccessful {
    background-color: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 684px;
    height: 356px;
    padding: 10px 0px 0px 0px;
    border-radius: 10px;

}

.isFetchSuccessful .succesIcon {
    background-color: #06D6A0;
    padding: 10px;
    width: 62px;
    height: 62px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.isFetchSuccessful .titleH4Bold {
    font-family: Manrope;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
}

.isFetchSuccessful .textBlack {
    font-family: Manrope;
    font-size: 20px;
    font-weight: 400;
    line-height: 27.32px;
    text-align: center;
    color: #00070E;
}

.isFetchSuccessful .button {
    width: 328px;
    text-align: center;
    margin: 0;
    display: flex;
    height: 39px;
    align-items: center;
    justify-content: center;
}

/* .workWithUsFormMainContainer .phoneContactContainer.edit .phoneContactDropdown .phoneContactInputContainer img {
    left: calc(100% - 90px);
} */

.workWithUsFormMainContainer .AddressRemoteContainer .phoneContactDropdown .phoneContactOptions{
    top: 104px;
}

.contact-us-forms form p.editButton {
    cursor: pointer;
    margin-top: 15px;
}

.workWithUsFormMainContainer .servicesDropdownContainer{
    position: relative;
    width: 100%;
}


@media (max-width: 768px) {

    .workWithUsFormMainContainer .contact-us-forms {
        min-width: calc(100% - 40px);
        padding: 0 20px;
        margin-bottom: 30px;
    }

    .phoneContactContainer .inputComponentContainer p{
        display: none;
    }

    .workWithUsFormMainContainer .errorContainer.email{
        width: 100%;
    }

    .workWithUsFormMainContainer .nameContainer,
    .workWithUsFormMainContainer .phoneEmailContainer,
    .workWithUsFormMainContainer .departamentCityContainer,
    .workWithUsFormMainContainer .AddressRemoteContainer {
        flex-direction: column;
    }

    .workWithUsFormMainContainer .phoneContactDropdown.phoneCode {
        width: 100%;
    }
 
    .isFetchSuccessful{
        width: calc(100% - 30px);
    }

    .workWithUsFormMainContainer .contact-us-forms form .isFetchSuccessful button{
        max-width: 80%;
    }
    .mainImgFormTechnologiesApply {
        height: 25vh;
    }

}
/*Telefono*/
@media (min-width: 300px) and (max-width: 700px) {
    .mainImgFormTechnologiesApply {
        height: 20vh;
    }
    .workWithUsFormMainContainer h2{
        font-size: 20px;
        line-height: unset;
    }
    .workWithUsFormMainContainer .form-text {
        font-size: 15px;
    }
    .workWithUsFormMainContainer .phoneEmailContainer, 
    .workWithUsFormMainContainer .AddressRemoteContainer {
        gap: 0;
    }
}