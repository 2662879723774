.VMAMainContainer .benefitsSection {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 50px;
}

.VMAMainContainer .benefitsSection .roofsContainer {
    flex-direction: row !important;
    max-width: 1100px;
}

.VMAMainContainer .benefitsSection .roofsContainer .targetComponentContainer {

    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.VMAMainContainer .benefitsSection .roofsContainer .targetComponentContainer img {
    width: 100%;
    height: 185px;
    border-radius: 10px;
}

.VMAMainContainer .benefitsSection .roofsContainer .targetComponentContainer .mainText {
    width: 100%;
    font-family: Manrope;
    font-size: 20px;
    font-weight: 400;
    line-height: 27.32px;
    text-align: center;
    color: #747474;
}

.VMAMainContainer .benefitsSection .roofsContainer .targetComponentContainer .mainText h1 {
    font-family: Manrope;
    font-size: 28px;
    font-weight: 700;
    line-height: 38.25px;
    text-align: center;
    color: #001D3D;
}

.imageVMA {
    max-width: 1012px;
    height: 816px;
    position: margin;
    top: 995px;
    left: 214px;
    gap: 0px;

}



.VMAMainContainer .cardServiceContainer {
    width: 508px;
    box-shadow: 4px 4px 15px 0px #00000040;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 10px;
    gap: 10px;
}

.VMAMainContainer .cardServiceContainer .cardTitleContainer {
    display: flex;
    align-items: center;
    gap: 10px;
}

.VMAMainContainer .cardServiceContainer .cardTitleContainer .imgCardService {
    display: flex;
    justify-content: center;
    align-items: center;
}

.VMAMainContainer .cardServiceContainer .cardTitleContainer .imgCardService svg {
    color: rgb(255, 255, 255);
}

.VMAMainContainer .cardServiceContainer .cardTitleContainer .imgCardService img {
    width: 100%;
}

.VMAMainContainer .cardServiceContainer .button {
    width: 100%;
    border-radius: 16px;
}

.VMAMainContainer .vmaServicesSection {
    display: flex;
    gap: 30px;
    justify-content: center;
}

.VMAMainContainer .services-portfolio .services-portfolio-height.VMA {
    background-image: linear-gradient(rgba(0, 29, 61, 0.4), rgba(0, 53, 102, 0.4)),
        url("../../../public/img/nurse-portrait-hospital -1 1.jpg");
    border-radius: 10px;
}

.VMAMainContainer .keyServicesSection .benefitsCardsComponentSection {
    margin-bottom: 85px;
    margin-top: 35px;
}

.VMAMainContainer .benefitsSection .secondH3 {
    max-width: 608px;
}

.vmaServicesSection .cardServiceContainer .mainTextCardService {
    font-family: Manrope;
    font-size: 20px;
    font-weight: 400;
    line-height: 27.32px;
    text-align: center;
    margin: 0;
    margin-bottom: 5px;
}

.VMAMainContainer #services-container.services-container .services-portfolio {
    margin-bottom: 85px;
    margin-top: 55px;
}

.VMAMainContainer .services-portfolio .services-portfolio-height {
    min-height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-size: cover;
    padding-bottom: 49px;
    background-position: center;
    background-repeat: no-repeat;
}

.VMAMainContainer .VMA .max-container-services.l{
    max-width: 54%;
}

.VMAMainContainer .VMA .max-container-services.l .services-portfolio-height p.textBanner{
    font-family: Manrope;
    font-size: 17px;
    font-weight: 500;
    text-align: left;    
}

@media (max-width: 768px) {

    .imageVMA {
        max-width: calc(100% - 20px); 
        height: auto;
        top: auto; 
        left: auto; 
        margin: 20px auto;
    }

    .VMAMainContainer .vmaServicesSection {
        flex-direction: column;
    }

    .VMAMainContainer .cardServiceContainer {
        width: calc(100% - 40px);
        margin: auto;
    }
}