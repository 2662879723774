.App {
    width: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    position: relative;
    margin-top: 80px;
}

.mainContainerHome {
    min-height: calc(100vh - 339px - 59px);
    max-width: 1400px;
    margin: auto;
    margin-bottom: 130px;
}

.mainContainerHome .welcomeMessageSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.mainContainerHome .welcomeMessageSection .title {
    font-family: Manrope;
    font-size: 52px;
    margin: 50px auto;
    max-width: 95vw;
}

.mainContainerHome .welcomeMessageSection .button {
    width: 230px;
    height: 45px;
}

.servicesSection {
    margin-top: 15px;
}

.servicesSection .title {
    font-family: Roboto;
    font-size: 38px;
    font-weight: 700;
    line-height: 35.16px;
    text-align: center;
}

.servicesSection .ServicesContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin: 0 auto;
    margin-top: 30px;
}

.moreServices.servicesSection .ServicesContainer {
    margin-top: 35px;
}

.servicesSection .ServicesContainer .cardServiceContainer {
    width: 391px;
    box-shadow: 4px 4px 15px 0px #00000040;
    background-color: var(--color-primary);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 10px;
}

.moreServices.servicesSection .ServicesContainer .cardServiceContainer {
    background-color: #fff;
}

.mainContainerHome .benefitsCardsComponentSection .benfitContainer {
    width: 391px;
}

.servicesSection .ServicesContainer .cardServiceContainer .mainTextCardService {
    margin-top: 0;
    color: #fff;
    padding: 0 10px;
    font-family: Manrope;
    font-size: 15px;
    font-weight: 500;
    text-align: justified;
}

.mainContainerHome .benefitsCardsComponentSection .cardsContainer {
    max-width: 1400px;
    gap: 24px;
}

.moreServices.servicesSection .ServicesContainer .cardServiceContainer .mainTextCardService {
    color: rgba(82, 82, 82, 1);
}

.servicesSection .ServicesContainer .cardServiceContainer .cardTitleContainer {
    display: flex;
    align-items: flex-start;
    padding: 0 10px;
    gap: 10px;
}

.servicesSection .ServicesContainer .cardServiceContainer .cardMainContainer .mainImg {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.servicesSection .ServicesContainer .cardServiceContainer .cardTitleContainer .imgCardService {
    background: rgba(6, 214, 160, 1);
    padding: 5px;
    border-radius: 50%;
    width: 33px;
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.moreServices.servicesSection .ServicesContainer .cardServiceContainer .cardTitleContainer .imgCardService {
    width: 45px;
    height: 45px;
}

.moreServices.servicesSection .ServicesContainer .cardServiceContainer .cardTitleContainer .imgCardService {
    background-color: var(--color-primary);
}

.servicesSection .ServicesContainer .cardServiceContainer .cardTitleContainer .imgCardService svg {
    color: rgb(255, 255, 255);
}

.servicesSection .ServicesContainer .cardServiceContainer .cardTitleContainer .imgCardService img {
    width: 16px;
}

.moreServices.servicesSection .ServicesContainer .cardServiceContainer .cardTitleContainer .imgCardService img {
    width: 22px;
}

.servicesSection .ServicesContainer .cardServiceContainer .button {
    max-width: 50%;
    margin-bottom: 10px;
    border-radius: 16px;
}

.mainContainerHome .roofSection {
    display: flex;
    width: 80%;
    margin: 0 auto;
    flex-direction: column;
}

.mainContainerHome .clientsMainContainer,
.mainContainerHome .partnersContainer {
    display: flex;
    justify-content: center;
    gap: 25px;
    margin-top: 35px;
    flex-wrap: wrap;
}

.mainContainerHome .clientsMainContainer.open {
    align-items: flex-start;
}

.mainContainerHome .partnersContainer {
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1220px;
    margin: auto;
    margin-top: 35px;
}

.mainContainerHome .imgMainContainer {
    max-height: 200px;
    overflow: hidden;
    margin-bottom: 10px;
}

.mainContainerHome .clientContainer {
    max-width: 391px;
    min-height: 294px;
    padding: 12px 15px 12px 15px;
    border-radius: 10px;
    box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.25);
    position: relative;
}

.mainContainerHome .moreServices {
    margin-top: 35px;
}

.mainContainerHome .moreServices .bannerMoreServices {
    background: url('../../public//img/more-services-banner.jpg');
    max-width: 1220px;
    height: 365px;
    margin: auto;
    margin-top: 35px;
}

.ServicesContainer h2 {
    text-align: center;
    color: rgba(6, 214, 160, 1);
    font-family: Manrope;
    font-size: 23px;
    margin: 0;
    margin-bottom: 23px;
    font-weight: 700;
    text-align: left;
    max-width: 85%;
}

.moreServices.servicesSection .ServicesContainer h2 {
    color: var(--color-primary);
}

.ServicesContainer .pCardServiceTitle {
    margin: 0;
    color: rgba(6, 214, 160, 1);
    margin-top: 2px;
    margin-bottom: 23px;
    font-family: Manrope;
    font-size: 20px;
    font-weight: 400;
    text-align: left;
}

.moreServices.servicesSection .ServicesContainer .h2 {
    font-size: 28px;
}

.mainContainerHome .roofSection h2 {
    text-align: center;
    margin-top: 100px;
    font-size: 38px;
}

.mainContainerHome .roofsContainer {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    justify-content: center;
    gap: 30px;
}

.mainContainerHome .targetComponentContainer {
    width: 330px;
}

.mainContainerHome .roofSection .roofsContainer .targetComponentContainer .mainText {
    font-family: Manrope;
    font-size: 15px;
    font-weight: 500;
    line-height: 20.49px;
    color: #525252;
    text-align: center;
}

.servicesSection .ServicesContainer .cardServiceContainer.tech .cardTitleContainer {
    gap: 5px;
}

.mainContainerHome .people360,
.mainContainerHome .clientStories,
.mainContainerHome .partner {
    margin-top: 85px;
}

.mainContainerHome .people360 .textBlack {
    font-family: Manrope;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
}

.mainContainerHome .people360 .mediumText {
    max-width: 800px;
    margin: auto;
}

.mainContainerHome .clientContainer .clientInfo .clientImgContainer {
    max-height: 86px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 86px;
    overflow: hidden;
}

.mainContainerHome .clientContainer .clientInfo .clientImgContainer img {
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.mainContainerHome .clientContainer .clientInfo {
    display: flex;
    gap: 15px;
}

.mainContainerHome .clientContainer .clientInfo h3 {
    font-family: Manrope;
    margin: 0;
    font-size: 24px;
    font-weight: 700;
    text-align: left;
}

.mainContainerHome .clientContainer .clientInfo h4 {
    font-family: Manrope;
    font-size: 20px;
    font-weight: 400;
    margin: 0;
    text-align: left;
}

.videoContainer {
    width: 100%;
    max-width: 1220px;
    background: rgba(15, 129, 255, 1);
    margin: 0 auto;
    border-radius: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mainContainerHome .VideoPlayer {
    background-color: rgba(15, 129, 255, 1);
    width: 100%;
}

.clientContainer p {
    text-align: justify;
}

.roofSection button {
    margin-top: 30px;
}

.App .InfoHomeContainer {
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
    max-width: 1220px;
    margin: auto;
    margin-top: 85px;
    margin-bottom: 50px;
}

.App .InfoHomeContainer .InfoHomeTextContainer .title,
.App .InfoHomeContainer .InfoHomeTextContainer .textBlack {
    text-align: justify;
}

.App .InfoHomeContainer .InfoHomeTextContainer .title {
    text-align: left;
}

.App .InfoHomeContainer .InfoHomeTextContainer .titleH4Bold {
    text-align: center;
}

.App .InfoHomeContainer .InfoHomeImg {
    width: 100%;
}

.App .ourNumberContainer {
    margin: auto;
    gap: 24px;
    display: flex;
    width: 100%;
    max-width: 1220px;
    justify-content: center;
    flex-wrap: wrap;
}

.App .ourNumberContainer .ourNumbersItem {
    box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    background: rgba(0, 29, 61, 1);
    padding: 12px 15px;
    border-radius: 10px;
    display: flex;
    gap: 10px;
    justify-content: left;
    align-items: center;
    width: 287px;

}

.App .ourNumberContainer .ourNumbersItem .ourNumbersImageContainer {
    background: rgba(6, 214, 160, 1);
    border-radius: 50%;
    min-height: 72px;
    min-width: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.App .ourNumberContainer .ourNumbersItem .ourNumbersText span {
    color: rgba(6, 214, 160, 1);
}

.App .readMoreHome {
    border: 1px solid #fff;
    background-color: #fff;
    font-family: Manrope;
    font-size: 13px;
    font-weight: 700;
    line-height: 13px;
    text-align: left;
    position: absolute;
    right: 10px;
    bottom: 15px;
    color: rgba(6, 214, 160, 1);
}

@media (min-width: 300px) and (max-width: 1200px) {
    .mainContainerHome .targetsSection .targetsContainer {
        display: flex;
        gap: 30px;
        margin: 0;
        flex-wrap: wrap;
    }

    .mainContainerHome .targetsSection {
        display: flex;
        width: 90%;
        margin: 0 auto;
        flex-direction: column;
    }

    .App .InfoHomeContainer {
        justify-content: center;
        align-items: center;
        display: flex;
        padding: 20px;
        width: 100%;
        max-width: 1220px;
        margin: auto;
        flex-wrap: wrap;
        margin-top: 85px;
        margin-bottom: 50px;
    }


    .mainContainerHome .partnersContainer {
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 1220px;
        margin: auto;
        margin-top: 35px;
    }


}

/*Tablet*/
@media (min-width: 700px) and (max-width: 910px) {

    .mainContainerHome .welcomeMessageSection .title {
        font-size: 35px;
        max-width: unset;
        padding: 30px;
        margin-bottom: 0;
    }

    .servicesSection .ServicesContainer .cardServiceContainer {
        width: 40vw;
    }

    .mainContainerHome .people360 .textBlack {
        padding: 0 30px;
    }

    .mainContainerHome .people360 .mediumText {
        padding: 0 30px;
    }

    .mainContainerHome .benefitsCardsComponentSection .benfitContainer {
        width: 40vw;
    }

    .videoContainer {
        width: 95%;
    }

    .mainContainerHome .moreServices .bannerMoreServices {
        width: 95%;
        height: 20vh;
        background-size: cover;
        background-position: center;
    }

    .mainContainerHome .clientContainer {
        max-width: 40vw;
        height: fit-content;
    }

    .mainContainerHome .clientContainer .clientInfo h4 {
        font-size: 17px;
    }

    .mainContainerHome .partnersContainer {
        gap: 30px;
    }

    .partnersContainer img {
        max-width: 40vw;
    }

    .mainContainerHome .roofSection {
        width: 95%;
    }
}

/*Telefono
TELEFONO*/
@media (min-width: 320px) and (max-width: 702px) {

    .mainContainerHome .welcomeMessageSection .title {
        font-size: 25px;
        width: 100%;
        max-width: unset;
        padding: 5px;
        margin-bottom: 0;
        line-height: unset;
    }

    .servicesSection .title {
        font-size: 23px;
    }

    .servicesSection {
        margin-top: 10;
    }

    .servicesSection .ServicesContainer .cardServiceContainer {
        margin: 0 17px;
    }

    .mainContainerHome .people360 {
        margin-top: 36px;
    }

    .mainContainerHome .people360 h2 {
        font-size: 25px;
    }

    .mainContainerHome .people360 .textBlack {
        font-size: 20px;
    }

    .mainContainerHome .people360 .mediumText {
        padding: 0 17px;
        font-size: 15px;
    }

    .mainContainerHome .benefitsCardsComponentSection .benfitContainer {
        margin: 0 17px;
    }

    .videoContainer {
        width: 90%;
    }

    .mainContainerHome .moreServices .bannerMoreServices {
        width: 90%;
        height: 20vh;
        background-size: cover;
        background-position: center;
    }

    .mainContainerHome .clientContainer {
        max-width: 90vw;
        height: fit-content;
    }

    .titleH2 {
        font-size: 30px;
    }

    .mainContainerHome .partnersContainer {
        gap: 30px;
    }

    .partnersContainer img {
        max-width: 90vw;
    }

    .mainContainerHome .roofSection h2 {
        font-size: 30px;
    }

    .mainContainerHome .roofSection {
        width: 90%;
    }
}