html {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: 'Manrope';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
}

main {
  margin-top: 80px;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  margin-bottom: 100px;
}

:root {
  --color-primary: #001D3D;
  --color-secondary: #001d3d;
  --color-background: #003566;
  --color-: #0075bf;
  --color-yellow-secondary: #ffd60a;
  --button-defaul-color: #06D6A0;
  --button-hover-color: #BCFFEE;
  --button-pressed-color: #004735;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input,
textarea {
  border: none;
  /* Elimina el borde */
  outline: none;
  /* Elimina el resaltado de enfoque */
}

* {
  box-sizing: border-box !important;
}

.button {
  border-radius: 16px;
  background: #06D6A0;
  margin: 0 auto;
  border: none;
  font-family: Roboto;
  min-height: 39px;
  text-align: center;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 700;
  line-height: 19.12px;
  width: 100%;
  max-width: 230px;
}

.button:hover {
  background-color: var(--button-hover-color);
}

.button:active {
  background-color: var(--button-pressed-color);
}


.title {
  font-family: Manrope;
  font-size: 38px;
  font-weight: 700;
  line-height: 51.91px;
  text-align: center;
  margin: 50px auto;
}

h1.title {
  margin-top: 50px;
}

.services-portfolio {
  margin-top: 50px;
}

.titleH2 {
  font-family: Manrope;
  font-size: 38px;
  font-weight: 700;
  text-align: center;
  margin: 0;
}

.titleH3 {
  font-family: Manrope;
  font-size: 28px;
  font-weight: 400;
  text-align: center;
  margin: 0;
}

.centerContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.titleH3Bold {
  font-family: Manrope;
  font-size: 28px;
  font-weight: 700;
  line-height: 33.6px;
  width: 100%;
  margin: 0;
  text-align: center;
}

.titleH4Bold {
  font-family: Manrope;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}

.mediumText {
  font-family: Manrope;
  font-size: 17px;
  font-weight: 500;
  line-height: 23.22px;
  text-align: justify;
}

.textBlack {
  font-family: Manrope;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  color: #00070E;
}

.textBlackBold {
  font-family: Manrope;
  font-size: 20px;
  font-weight: 700;
  line-height: 27.32px;
  text-align: left;
  margin: 0;
  height: fit-content;
}

a.link {
  display: flex;
  text-align: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  align-items: center;
  color: #001D3D;
}

a.link:active {
  color: #fff;
}

.textH3 {
  font-family: Manrope;
  font-size: 28px;
  font-weight: 400;
  text-align: center;
}

.textH4 {
  font-family: Manrope;
  font-size: 24px;
  font-weight: 400;
  line-height: 32.78px;
  margin: 0;
  text-align: center;
}

.titleH4Bold {
  font-family: Manrope;
  font-size: 24px;
  font-weight: 700;
  text-align: left;
}

.loadinContainer,
.loadincContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.removeButton {
  height: 51px;
  width: fit-content;
  display: flex;
  align-items: center;
  margin-left: 5px;
}

/*Telefono*/
@media (min-width: 300px) and (max-width: 700px) {
  .services-portfolio {
    margin-top: 30px;
  }

  .titleH3Bold {
    font-size: 22px;
  }

  .textH4 {
    font-size: 20px;
  }
}