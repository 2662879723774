.specialistMaincontainer .banner {
    height: 500px;
    width: 100vw;
    background-image: url('../../../../public/img/front-view-nurses-team-hospital 2.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
}

.vmaSpecialistSection {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    max-width: 1100px;
    margin: auto;
    justify-content: center;
    margin-top: 70px;
}

.vmaSpecialistSection .cardServiceContainer {
    width: 331px;
    box-shadow: 4px 4px 15px 0px #00000040;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 15px;
    min-height: 466px;
    align-items: flex-start;
    border-radius: 10px;
}

.vmaSpecialistSection .cardServiceContainer .cardTitleContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}

.vmaSpecialistSection .cardServiceContainer .cardTitleContainer .imgCardService {
    display: flex;
    justify-content: center;
    align-items: center;
}

.vmaSpecialistSection .cardServiceContainer .cardTitleContainer .imgCardService svg {
    color: rgb(255, 255, 255);
}

.vmaSpecialistSection .cardServiceContainer .cardTitleContainer .imgCardService img {
    width: 100%;
}

.vmaSpecialistSection .cardServiceContainer .button {
    width: 100%;
    border-radius: 16px;
}

.vmaSpecialistSection .vmaServicesSection {
    display: flex;
    gap: 30px;
    justify-content: center;
}

.specialistMaincontainer .buttonInfo {
    margin-top: 85px;
    gap: 25px;
}

.vmaSpecialistSection .keyServicesSection .benefitsCardsComponentSection {
    margin-bottom: 85px;
    margin-top: 35px;
}

.vmaSpecialistSection .benefitsSection .secondH3 {
    width: 608px;
}

.vmaSpecialistSection .cardServiceContainer .mainTextCardService {
    margin: 0;
    color: #525252;
    font-family: Manrope;
    font-size: 17px;
    font-weight: 500;
    line-height: 23.22px;
    text-align: left;
}

.vmaSpecialistSection #services-container.services-container .services-portfolio {
    margin-bottom: 85px;
    margin-top: 55px;
}

.vmaSpecialistSection .cardServiceContainer h2.titleBold {
    font-family: Manrope;
    font-size: 24px;
    font-weight: 700;
    line-height: 32.78px;
    text-align: center;
    margin: 0;
    width: 100%;
}

.specialistMaincontainer .buttonInfo.centerContent .titleH3{
    margin: 0 auto;
    margin-bottom: 45px;
}

.specialistMaincontainer .mainTitleComponentSection .title {
    max-width:59%;
}

.specialistMaincontainer .mainTitleComponentSection p.mainTextTitle{
    font-size: 24px;
}

.specialistMaincontainer .buttonInfo.centerContent .titleH3{
    margin: 0 auto;
}