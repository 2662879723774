.footer-container {
  background-color: var(--color-primary);
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Manrope;
}

.footer-content {
  display: flex;
  gap: 70px;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.logo-5ig-footer {
  width: 209px;
  height: 130px;
}

.footer-logos {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.policy-approval {
  color: white;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  max-width: 600px;
  font-size: 17px;
  line-height: 19.92px;
  letter-spacing: -1px;
  text-align: left;
}

.line-middle {
  height: 14rem;
  width: 3px;
  background: rgba(255, 255, 255, 1);
  align-self: center;
}

.policy-title-links {
  color: white;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.policy-title-links h3 {
  margin: 0;
  margin-bottom: 5px;
  font-size: 30px;
  font-weight: 700;
  text-align: left;
}

.policy-title-links a {
  color: #06D6A0;
  width: 250px;
  font-size: 24px;
  font-weight: 600;
  text-align: left;
}

.policy-logos-info {
  color: white;
}

.policy-logos-info p {
  color: #ffffff;
  font-size: 24px;
  font-family: Manrope;
  font-weight: 600;
  text-align: left;
}

.buttonWhatsapp {
  background-color: #06D6A0;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 30px;
  top: calc(100vh - 60px);
  z-index: 1000;
}

.buttonWhatsapp a {
  height: 25px;
}


.footer-copyright {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
  color: #ffffff;
  width: 100%;
}

.emailFooterContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.addresContainer {
  display: flex;
  margin-top: 30px;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
}

.addresContainer p {
  font-family: Manrope;
  font-size: 13px;
  font-weight: 500;
  margin: 0;
  text-align: left;
}

.emailFooterContainer p {
  margin: 0 0 0 10px;
  font-family: Manrope;
  font-size: 15px;
  font-weight: 700;
  text-align: center;
}

.footerMessage{
  max-width: 500px;
}

@media (min-width: 200px) and (max-width: 900px) {
  .footer-container {
    padding: 30px 40px;
  }

  .footer-content {
    flex-direction: column;
  }

  .policy-approval {
    font-size: 14px;
  }

  .sig-solutions-datapolicy-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .line-middle {
    display: none;
  }
}