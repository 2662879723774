.benefitsCardsComponentSection {
    margin-bottom: 50px;
}

.benefitsCardsComponentSection .title {
    font-family: Manrope;
    font-size: 38px;
    font-weight: 700;
    line-height: 51.91px;
    text-align: center;
    margin-bottom: 50px;
}

.benefitsCardsComponentSection .benefitTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.benefitsCardsComponentSection p.titleText {
    font-family: Manrope;
    font-size: 24px;
    font-weight: 700;
    line-height: 32.78px;
    text-align: left;
    width: 78%;
    margin: 0;
}

.benefitsCardsComponentSection p.secondaryText {
    font-family: Manrope;
    font-size: 20px;
    font-weight: 400;
    color: #525252;
    text-align: left;
}

.benefitsCardsComponentSection .cardsContainer {
    display: flex;
    max-width: 1100px;
    margin: auto;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
}

.benefitsCardsComponentSection .benfitContainer {
    box-shadow: 4px 4px 15px 0px hsla(0, 0%, 0%, 0.25);
    padding: 15px;
    width: 330px;
    border-radius: 10px;
}

.benefitsCardsComponentSection .imgCardBenefit {
    background-color: var(--color-primary);
    padding: 5px;
    border-radius: 50%;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
}

.benefitsCardsComponentSection p {
    font-family: Manrope;
    font-size: 20px;
    font-weight: 700;
    line-height: 27.32px;
    margin: 0;
    margin-top: 10px;
    text-align: left;
    color: var(--color-primary);
}