.container_content {
  padding: 20px 25px;
  height: auto;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  text-align: justify;
  align-items: center;
  background-color: red;
  border-width: 2px;
  justify-content: center;
  gap: 20px;
  background-color: white;
  max-width: 1220px;
  height: auto;
  border-radius: 10px;
  box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.25);
  margin: auto;
  margin-bottom: 30px;
}

.container_content h2 {
  font-family: Manrope;
  font-size: 24px;
  font-weight: 700;
  line-height: 32.78px;
  text-align: left;
  color: var(--color-primary);
}

.desc_service h2.secondTitle {
  font-family: Manrope;
  font-size: 24px;
  font-weight: 700;
  line-height: 32.78px;
  text-align: center;

}

.container_content p {
  font-family: Manrope;
  font-size: 20px;
  font-weight: 400;
  line-height: 27.32px;
  text-align: left;

}

.desc_service {
  /*creo que me toca calcularlo para el responsive (height_padre - altura_texto)/2 */
  text-align: center;
  grid-column: span 3;
}

.image_service {
  width: 100%;
  height: 333px;
  grid-column: span 2;
  border-radius: 10px;
}

.parent_container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;
}

.parent_container div:last-child {
  margin-bottom: 0;
  border-bottom: 0;
  grid-column: span 3;
}

.line {
  height: 1px;
  margin-top: 60px;
  background-color: black;
}

/* 
Esta(.content-services) es la que define la separacion entre la seccion de mas servicios y el resto */

.content-services {
  position: relative;
  width: 100%;
  z-index: 80;
  height: auto;
}

/*------------------------------------------------------------------------------------------------------------------------------------*/


.content-services .img-banner.ia {
  background-image: linear-gradient(rgba(0, 29, 61, 0.4), rgba(0, 53, 102, 0.4)),
    url("../../../../public/img/DreamShaper_v7_artificial_intelligence_and_machine_learning_1 2.jpg");
}

.content-services .img-banner.rpa {
  background-image: linear-gradient(rgba(0, 29, 61, 0.4), rgba(0, 53, 102, 0.4)),
    url("../../../../public/img/DreamShaper_v7_Robot_doing_repetitive_tasks_on_a_computer_serv_3 2.jpg");
}

.content-services .img-banner.cluod {
  background-image: linear-gradient(rgba(0, 29, 61, 0.4), rgba(0, 53, 102, 0.4)),
    url("../../../../public/img/DreamShaper_v7_cloud_server_helping_a_growing_company_2 2.jpg");
}


.img-banner {
  width: 100vw;
  height: 270px;
  margin-top: 80px;
  background: linear-gradient(270deg, rgb(217, 217, 217) 0%, rgb(0, 29, 61) 60%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
}

.img-banner h1 {
  z-index: 10000;
  color: white;
  font-family: Manrope;
  font-size: 38px;
  font-weight: 700;
  line-height: 51.91px;
  text-align: left;
}

.img-banner img {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.6;
}

.img-banner button {
  width: 284px;
  margin: 0;
  margin-top: 30px;
  height: 54px;
  padding: 16px 28px 16px 28px;
  background: var(--button-defaul-color);
  color: var(--color-secondary);
  border-radius: 16px;
  cursor: pointer;
  border: none;
  font-weight: 750;
  font-size: 20px;
  line-height: 20px;
}

.arrow-container {
  position: absolute;
  top: calc(45% - 10.60875rem);
  left: 5.9375rem;
  background: rgba(255, 195, 0, 1);
  width: 2.375rem;
  height: 2.375rem;
  border-radius: 0.625rem;
  padding: 0.25rem;
}

.arrow-container img:hover {
  opacity: 0.5;
}

.toolsContainter{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-direction: row;
  flex-wrap: wrap;
}

.back-link {
  background: no-repeat center center;
  cursor: pointer;
  color: red;
}

.img-banner .superposition {
  background-image: linear-gradient(rgba(0, 29, 61, 0.4),
      rgba(0, 53, 102, 0.4));
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: relative;
}

.more-services-background {
  padding-top: 35px;
  width: 100%;
  height: auto;
}

.more-services-container {
  width: 1220px;
  height: auto;
  margin: 0 auto;
  padding-bottom: 50px;
}

.more-services-title {
  height: 90px;
  width: 100%;
  display: flex;
  margin-bottom: 35px;
}

.more-services-title>div:nth-child(1) {
  width: 119px;
  height: 2px;
  background-color: rgba(0, 29, 61, 1);
  align-self: center;
}

.more-services-title>div:nth-child(3) {
  width: 100%;
  height: 2px;
  background-color: rgba(0, 29, 61, 1);
  align-self: center;
}

.more-services-title h1 {
  font-family: Manrope;
  font-size: 38px;
  font-weight: 700;
  text-align: center;
  margin: auto;
}

.more-services-content-grid {
  margin: 0 auto;
  width: auto;
  height: auto;
  border-radius: 45px;
  gap: 40px;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
}

.more-services-content-grid-item {
  padding: 20px;
  border: solid 3px rgba(0, 29, 61, 1);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
}

.more-services-content-grid-item-content {
  height: 102px;
  display: flex;
  gap: 30px;
  align-items: center;
}

.more-services-content-grid-item-content img {
  width: 45px;
  height: 40px;
}

.more-services-content-grid-item-content h1 {
  font-weight: 500;
  font-size: 34px;
  line-height: 41.15px;
  letter-spacing: -1.46px;
  align-self: center;
  margin: 0;
}

.more-services-content-grid-item-content-button {
  max-width: 100%;
}

.title_button_container {
  width: fit-content;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 15%;
}

/* 
.title_button_container button.button.ia{
  margin-left: 13%;
}

.title_button_container button.button.web-app-dev{
  margin-left: 8%;
}

.title_button_container button.button.cloud,
.title_button_container button.button.rpa{
  margin-left: 6%;
}

.title_button_container button.button.bi{
  margin-left: 15%;
} */

/* .title_button_container button{
  margin-left: 220px;
} */

.parent_container .button {
  margin: 0 auto;
  margin-top: 30px;
}

.moreServicesImgContainer {
  background-color: #06D6A0;
  border-radius: 50%;
  padding: 12px;
}

.container_content .desc_service img {
  margin-right: 10px;
}
/* IPAD */
@media (max-width: 910px) {
  .image_service {
    width: 100%;
    height: auto;
    margin-top: 2rem;
  }

  .parent_container {
    background-color: white;
    width: auto;
    margin: 25px;
    margin-bottom: 0;
    border-radius: 10px;
    height: auto;
    box-shadow: 4px 4px 20px 0px #00000040;
    padding: 0px 30px 70px 30px;
  }

  .container_content {
    height: auto;
    display: block;
    text-align: justify;
    margin-top: 20px;
    border-bottom: 0.5px solid gray;
    border-width: 2px;
    padding: 1rem;
  }

  .parent_container div:last-child {
    margin-bottom: 2rem;
    border-bottom: 0;
  }

  .container_content h2 {
    font-size: 30px;
    font-weight: 900;
    line-height: 36px;
    text-align: left;
    margin-top: 20px;
    color: rgba(0, 29, 61, 1);
  }

  .container_content p {
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: -1.4600000381469727px;
    text-align: left;
    margin: 0;
    color: rgba(0, 29, 61, 1);
  }

  .more-services-content-grid {
    margin: 0 auto;
    width: auto;
    height: auto;
    background-color: white;
    border-radius: 45px;
    padding: 70px 30px 70px 30px;
    gap: 40px;
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
  }

  .more-services-content-grid-item {
    padding: 20px;
    border: solid 3px rgba(0, 29, 61, 1);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: center;
  }

  .more-services-content-grid-item-content img {
    width: 60px;
  }

  .more-services-content-grid-item-content h1 {
    height: auto;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: -1.46px;
    align-self: center !important;
    margin: 0;
  }

  .more-services-container {
    width: auto;
    height: auto;
    margin: 0 auto;
    padding-bottom: 50px;
  }

  .more-services-title h1 {
    width: auto;
    line-height: 35px;
  }

  .title_button_container {
    margin: 0 20px;
    position: static;
    width: calc(100% - 40px);
  }

  .arrow-container {
    display: none;
  }
}
/* TELEFONO */
@media (min-width: 300px) and (max-width: 700px) {
  .img-banner h1 {
    font-size: 25px;
    line-height: 25px;
  }
  .container_content h2 {
    font-size: 20px;
  }
  .container_content p {
    font-size: 15px;
    text-align: justify;
  }
  .more-services-content-grid-item-content h2{
    font-size: 17px;
  }
}