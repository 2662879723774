.targetsSection,
.RecruitmentMainContainer .roofSection {
    display: flex;
    width: 80%;
    margin: 0 auto;
    flex-direction: column;
}

.targetsSection .targetsContainer {
    display: flex;
    margin: 0 auto;
    gap: 30px;
}

.targetsSection .targetsContainer .targetHomeContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-grow: 1;
    height: 344px;
    width: 330px;
    gap: 0px;
    border-radius: 20px;
    margin-bottom: 50px;
}

.targetsSection .title .work {
    font-family: Manrope;
    font-size: 38px;
    font-weight: 700;
    line-height: 51.91px;
    text-align: center;
    margin: 100px 0 30px 0;
}

.targetsSection .targetsContainer .mainText {
    font-family: Manrope;
    font-size: 17px;
    font-weight: 500;
    line-height: 23.22px;
    text-align: left;
    padding: 0 20px;
    margin: 0;
    margin-bottom: 20px;
}

.targetsSection .targetsContainer img {
    width: 100%;
}

.targetsSection .targetsContainer .targetHomeContainer {
    background-color: #0F81FF;
    color: #fff;
    padding-top: 35px;
}

.targetsSection .targetsContainer .targetHomeContainer:nth-child(2) {
    background-color: #06D6A0;
}

.targetsSection .targetsContainer .targetHomeContainer:nth-child(2),
.targetsSection .targetsContainer .targetHomeContainer:nth-child(2) h2 {
    color: #000000;
}

.targetsSection .targetsContainer .targetHomeContainer h2 {
    font-family: Manrope;
    font-size: 24px;
    color: #fff;
    max-width: 220px;
    font-weight: 700;
    line-height: 32.78px;
    text-align: left;
    padding: 0 20px;
}

.targetsSection .targetsContainer .targetHomeContainer p {
    font-family: Manrope;
    font-size: 17px;
    font-weight: 500;
    line-height: 23.22px;
    text-align: left;
}

.targetsSection .button,
.RecruitmentMainContainer .roofSection .button {
    width: 334px;
    height: 45px;
    margin: 0 auto;
    margin-top: 30px;
}

.RecruitmentMainContainer .services-portfolio-height.Recruitment {
    background-image: linear-gradient(rgba(0, 29, 61, 0.4), rgba(0, 53, 102, 0.4)),
        url("../../../public/img/futurism-perspective-digital-nomads-lifestyle 1.jpg");
    border-radius: 10px;
    align-items: flex-start;
    justify-content: center;
}

.RecruitmentMainContainer .services-container {
    max-height: 410px; /* Ajusta según sea necesario */
    overflow: auto;
}

.RecruitmentMainContainer .services-portfolio-height.Recruitment .max-container-services.l {
   
    display: flex;
    flex-direction: column;
    text-align: center;
}

.RecruitmentMainContainer .services-portfolio-height.second h2.titleBanner2.titleBanner {
    font-family: Manrope;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
}

.RecruitmentMainContainer .services-portfolio-height.second h1.titleBanner {
    font-family: Manrope;
    font-size: 28px;
    font-weight: 700;
    text-transform:capitalize;
    text-align: center;    
}

.RecruitmentMainContainer .services-portfolio-height p.textBanner {
    font-family: Manrope;
    font-size: 24px;
    font-weight: 700;
    max-width: 400px;
    margin: 10;
    text-align: left;
}

.RecruitmentMainContainer .max-container-services button{
    margin-top: 0;
    width: 241px;
}

.RecruitmentMainContainer .textH3 {
    max-width: 686px;
    margin: 25px auto;
}

.RecruitmentMainContainer .positionsApplySection {
    margin-top: 30px;
    margin-bottom: 50px;
}

.additionalCardsSection {
    margin-top: 30px;
}

.additionalCardsContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    width: 100%;
    max-width: 1050px;
    margin: 0 auto;
}
.RecruitmentMainContainer .mainTextTitle {
    font-family: Manrope;
    font-size: 28px;
    font-weight: 400;
    max-width: 859px;
    margin: 15px auto; /* Ajusta el margen superior aquí */
    text-align: center;
}

.RecruitmentMainContainer .sectionTitle {
    font-family: Manrope;
    font-size: 38px;
    font-weight: 700;
    line-height: 51.91px;
    text-align: center;
    margin: 30px 0; /* Ajusta el margen superior aquí para el nuevo título */
}


.additionalCard {
    width: 330px;
    height: 156px;
    padding: 12px 15px;
    border-radius: 10px;
    box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
}

.cardTitle {
    font-family: Manrope;
    font-size: 28px;
    font-weight: 700;
    line-height: 38.25px;
    text-align: center;
}


.mainTextTitle {
    font-family: Manrope;
    font-size: 28px;
    font-weight: 400;
    max-width: 859px;
    margin: 30px auto;
    text-align: center;
}



.imageGridSection {
    display: flex;
    flex-direction: column;
    align-items: center; 
    width: 100%;
}

.title {
    font-family: Manrope;
    font-size: 38px;
    font-weight: 700;
    line-height: 51.91px;
    text-align: center;
    margin: 50px 0 50px 0;
}

.imageGridContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
    align-items: center;
    justify-items: center;
    max-width: 1100px;
    width: 100%;
}

.imageGridContainer img {
    height: auto;
    object-fit: cover;
}

.imageMicrosoft {
    width: 391px;
}

.imagePlatzi {
    width: 287px;
}

.imageUdemy {
    width: 287px;
}

.imageCoursera {
    width: 287px;
}

.imageGoogle {
    width: 390px;
}

.imageIBM {
    width: 280px;
}



@media (max-width: 1200px) {

    .targetsSection .targetsContainer {
        flex-wrap: wrap;
    }

    .targetsSection .targetsContainer .targetHomeContainer {
        height: 100%;
    }
    .imageGridContainer {
        grid-template-columns: repeat(2, 1fr);
    }
    .additionalCardsContainer {
        grid-template-columns: repeat(2, 1fr);
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .additionalCard {
        flex: 1 0 45%;
        max-width: 300px;
        margin-bottom: 20px;
    }

}

/*Tablet*/
@media (min-width: 702px) and (max-width: 910px) {
    .targetsSection .targetsContainer {
        flex-direction: row;
        width: 100%;
        justify-content: center;
    }

    .targetsSection .targetsContainer .targetHomeContainer {
        width: 45vw;
        height: 100%;
        flex-grow: unset;
        margin-bottom: 10px;
    }
    .imageGridContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 40px;
    }
    .mainTitleComponentSection h1{
        margin: 0;
        padding: 0 40px;
    }
    .RecruitmentMainContainer .mainTextTitle {
        padding: 0 40px;
        font-size: 23px;
    }
    .RecruitmentMainContainer .sectionTitle {
        font-size: 30px;
    }
    .targetsSection{
        width: 95%;
    }
    .title{
        font-size: 30px;
    }
    .imageGridContainer img{
        max-width: 40vw;
    }
}
   
  /*Telefono*/
@media (min-width: 320px) and (max-width: 702px) {
    .targetsSection .targetsContainer {
        flex-wrap: wrap;
    }
    .additionalCardsContainer {
        gap: 10px;
    }

    .additionalCard {
        flex: 1 0 100%;
        max-width: 90vw;
        height: 10vh;
    }
    .mainTitleComponentSection h1{
        margin: 0;
    }
    .RecruitmentMainContainer .mainTextTitle {
        padding: 0 20px;
        font-size: 17px;
    }
    .RecruitmentMainContainer .services-container {
        height: 35vh;
    }
    .RecruitmentMainContainer .sectionTitle {
        font-size: 25px;
    }
    .targetsSection .targetsContainer .targetHomeContainer {
        margin-bottom: 10px;
    }
    .cardTitle{
        font-size: 20px;
    }
    .title{
        font-size: 25px;
    }
    .imageGridContainer img{
        max-width: 60vw;
        
    }
    .imageGridContainer {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        gap: 40px;
    }
}