.articleMainContainer .articleBanner {
    background-image: linear-gradient(rgba(0, 29, 61, 0.4), rgba(0, 53, 102, 0.4)),
        url("../../../../public/img/electricity-flowing-smooth-blue-abstract-wave-generated-by-ai 1.jpg");
    height: 268px;
    margin-bottom: 50px;
    word-wrap: break-word;
    display: flex;
    justify-content: center;
    background-position: center;
    background-size: cover;
}

.articleMainContainer .articleBanner .title {
    color: #ffffff;
    max-width: 67%;
    font-size: 52px;
}

.articleMainContainer .articleContainer {
    display: flex;
    max-width: 1400px;
    gap: 20px;
    margin: auto;
    width: 90vw;
}

.articleMainContainer .articleContainer .mainTextContainer {
    width: 70%;
    word-wrap: break-word;
}

.articleMainContainer .articleContainer .mainTextContainer .mainText {
    font-family: Manrope;
    font-size: 24px;
    font-weight: 400;
    line-height: 32.78px;
    text-align: justify;
    color: #525252;
    margin-top: 0;
}

.articleMainContainer .articleContainer .mainTextContainer .mainText .ck-content.stickystyles-wrapper-insert {
    font-family: Manrope !important;
    font-size: 24px !important;
    font-weight: 400;
    line-height: 32.78px;
    text-align: justify;
    color: #525252;
    margin-top: 0;
}

.articleMainContainer .latestNews {
    box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.25);
    padding: 0;
    height: fit-content;
    border-radius: 10px;
}

.articleMainContainer .latestNews .articleCardContainer {
    margin: 0 auto;
    max-width: 361px;
    margin: 15px;
    word-wrap: break-word;
}

.articleMainContainer .latestNews .articleCardContainer .titleH4Bold {
    margin: 15px 0;
    color: rgba(0, 29, 61, 1);
}

.articleMainContainer .socialMediaShareLinks {
    display: flex;
    box-shadow: 3px 4px 10px 0px rgba(0, 0, 0, 0.25);
    padding: 22px;
    align-items: center;
    border-radius: 10px;
    max-width: 391px;
    gap: 30px;
}

.articleMainContainer .socialMediaShareLinks p {
    margin: 0;
    font-family: Manrope;
    font-size: 20px;
    font-weight: 700;
    line-height: 27.32px;
    text-align: left;
}

.articleMainContainer .socialMediaShareLinks .socialMediaIcons {
    display: flex;
    gap: 20px;
}

.articleMainContainer .socialMediaShareLinks .socialMediaIcons a{
    display: flex;
    justify-content: center;
    align-items: center;
}

.articleMainContainer .socialMediaShareLinks img {
    width: 24px;
}
/*Tablet*/
@media (min-width: 700px) and (max-width: 910px) {
   
    .articleMainContainer .articleBanner .title {
        max-width: 95%;
        font-size: 35px;
    }
    .articleMainContainer .articleContainer {
        flex-direction: column;
        width: 100vw;
        align-items: center;
    }
    .articleMainContainer .articleBanner {
        margin-bottom: 10px;
    }
    
    .articleMainContainer .articleContainer .mainTextContainer {
        width: 90%;
       
    }
    .articleMainContainer .socialMediaShareLinks .socialMediaIcons {
     
        width: 80%;
        justify-content: space-around;
    }
    .articleMainContainer .latestNews {
        width:100vw;
        display: flex;
        justify-content: center;
       flex-wrap: wrap;
       box-shadow: none;
    }
    .articleMainContainer .latestNews .articleCardContainer {
        padding: 10px;
        width: 40vw;
        box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.25);
    }
    .articleCardContainer img{
        width: 100%;
    }
    .latestNews h2{
        width: 100%;
        margin: 35px 0;
    }
    .articleMainContainer .articleContainer .mainTextContainer .mainText {
        margin-top: 20px;
    }

  }
   
/*Telefono*/
@media (min-width: 320px) and (max-width: 702px) {
    .articleMainContainer .articleBanner .title {
        max-width: 90%;
        font-size: 20px;
        line-height: unset;
    }
    .articleMainContainer .articleBanner {
        height: 200px;
        margin-bottom: 10px;
    }
    .articleMainContainer .articleContainer {
        flex-direction: column;
        align-items: center;
        width: 100vw;
    }
    .articleMainContainer .articleContainer .mainTextContainer .mainText {
        font-size: 17px;
        line-height: unset;
        margin-top: 20px;
    }
    .articleMainContainer .articleContainer .mainTextContainer {
        width: 90%;
    }
    .articleMainContainer .socialMediaShareLinks .socialMediaIcons {
        width: 80%;
        justify-content: space-around;
    }
    .articleMainContainer .socialMediaShareLinks {
        margin: 20px 0;
    }
    .articleMainContainer .latestNews {
        width:90vw;
       box-shadow: none;
    }
    .articleCardContainer img{
        display: none;
    }
    .articleMainContainer .latestNews .articleCardContainer {
        padding: 15px;
        width: 90%;
        box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
    }

}