.formSpecialistHireMaincontainer .mainSpecialistHireForm {
    width: 100vw;
    height: 500px;
    background-image: url('../../../../../public/img/big dr foto 1.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.formSpecialistHireMaincontainer .phoneContactContainer.hour{
    width: 242px;
}

.formSpecialistHireMaincontainer .meetCanidadteContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.formSpecialistHireMaincontainer .phoneContactContainer.hour .dropdownsContainer{
    width: 100%;
}

.formSpecialistHireMaincontainer .MuiInputLabel-formControl,
.formSpecialistHireMaincontainer .MuiOutlinedInput-notchedOutline {
    display: none;
}

.formSpecialistHireMaincontainer .MuiInputBase-root {
    width: 242px;
    height: 48px;
    gap: 0px;
    border-radius: 14px;
    padding: 0;
    border: 2px solid var(--color-primary);
    ;
}

.formSpecialistHireMaincontainer .MuiButtonBase-root.MuiIconButton-root {
    background-color: var(--color-primary);
    ;
    color: #fff;
    margin-top: 0;
    height: 48px;
    margin-right: 0;
    width: 50px;
    border-radius: 0px 14px 14px 0px;

}

.base-Popper-root.MuiPickersPopper-root{
    margin: 20px auto !important;
}

.MuiDateCalendar-root, .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded{
    border-radius: 10px;
}

.MuiDateCalendar-root .MuiPickersCalendarHeader-root {
    background-color: var(--color-primary);
    ;
    margin-top: 0;
    min-height: 65px;
}

.MuiDateCalendar-root .MuiPickersCalendarHeader-labelContainer {
    color: #fff;
}

.MuiDateCalendar-root .MuiSvgIcon-root path {
    color: #fff;
}

.MuiDateCalendar-root .MuiTypography-root {
    font-family: Manrope;
    font-size: 13px;
    font-weight: 700;
    text-align: left;
    color: var(--color-primary);
}

.MuiDateCalendar-root .MuiButtonBase-root {
    font-family: Manrope;
    font-size: 13px;
    font-weight: 500;
    text-align: left;
    color: var(--color-primary);
}

.MuiDateCalendar-root .MuiButtonBase-root.MuiPickersDay-root.Mui-selected,
.MuiDateCalendar-root .MuiPickersYear-yearButton.Mui-selected{
    background-color: var(--color-primary);
}

@media (max-width: 768px) {

    .formSpecialistHireMaincontainer .meetCanidadteContainer{
        flex-direction: column;
    }

    .formSpecialistHireMaincontainer .MuiInputBase-root,
    .formSpecialistHireMaincontainer .MuiStack-root,
    .formSpecialistHireMaincontainer .phoneContactContainer.hour,
    .formSpecialistHireMaincontainer .containerHour{
        width: 100%;
    }

    .formSpecialistHireMaincontainer .containerHour{
        margin-top: 20px;
    }
}