.aboutMaincontainer {
    padding: 0 20px;
}

/* Estilos para .roofsContainer */
.aboutMaincontainer .roofsContainer {
    display: flex;
    max-width: 1220px;
    margin: 0 auto;
}

/* Estilos generales para tarjetas dentro de .roofsContainer */
.aboutMaincontainer .roofsContainer .targetComponentContainer,
.aboutMaincontainer .roofsContainer .teamCardContainer {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    border-radius: 10px;
    padding: 12px 15px;
    box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.25);
}

/* Estilos para targetComponentContainer */
.aboutMaincontainer .roofsContainer .targetComponentContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 20px;
    min-height: 320px;
}

.aboutMaincontainer .roofsContainer .targetComponentContainer.second {
    flex-direction: row-reverse;
}

.aboutMaincontainer .roofsContainer .targetComponentContainer img {
    border-radius: 10px;
    width: 51%;
}

.aboutMaincontainer .roofsContainer .targetComponentContainer .mainText {
    font-family: Manrope;
    font-size: 24px;
    font-weight: 400;
    line-height: 32.78px;
    text-align: justified;
    margin: 0;
}

.aboutMaincontainer .roofsContainer .targetComponentContainer .mainTitle {
    font-family: Manrope;
    font-size: 38px;
    font-weight: 700;
    line-height: 51.91px;
    text-align: left;
    margin: 0;
}

.aboutMaincontainer .roofsContainer .targetComponentContainer .textContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    height: 60%;
    /* Ajusta la altura de la tarjeta */
}

/* Estilos específicos para .teamCardContainer */
.aboutMaincontainer .roofsContainer .teamCardContainer {
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    /* Centra verticalmente el contenido */
    gap: 20px;
}

.teamCardContainer .imageContainer {
    flex: 1;
    /* Ajusta el tamaño para que ocupe la mitad del contenedor */
    display: flex;
    justify-content: center;
    /* Centra la imagen horizontalmente */
    align-items: center;
    /* Centra la imagen verticalmente dentro del contenedor */
}

.teamCardContainer .imageContainer img {
    width: 100%;
    height: auto;
    /* Mantiene la proporción de la imagen */
    border-radius: 10px;
    object-fit: cover;
}

.teamCardContainer .textContainer {
    flex: 1;
    /* Ajusta el tamaño para que ocupe la otra mitad del contenedor */
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.teamCardContainer .textContainer .mainTitle {
    font-family: 'Manrope', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 38.25px;
    text-align: left;
    margin-bottom: 1px;
    /* Ajusta el margen inferior para reducir el espacio entre el título y la descripción */
}

.teamCardContainer .textContainer .mainText {
    font-family: 'Manrope', sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 20.49px;
    text-align: left;
    color: #747474;
}

.teamCardContainer .textContainer .logo {
    position: absolute;
    bottom: 20px;
    right: 10px;
    width: 25px;
    height: 30px;
}

.teamCardContainer .textContainer .logo img {
    width: 100%;
    height: auto;
    border-radius: 10%;
}

/* Oculta el logo por defecto */
.teamCardContainer .textContainer .logo {
    display: none;
}

/* Muestra el logo solo si hay un enlace de LinkedIn */
.teamCardContainer .textContainer a.logo {
    display: block;
}

/* Estilos para la sección de imagen y texto */
.aboutMaincontainer .bannerWorkWithUS {
    background: linear-gradient(rgba(0, 29, 61, 0.4), rgba(0, 53, 102, 0.4)),
        url('../../../public/img/young-adult-programmer-working-indoors-night-using-computer-generated-by-artificial-intelligence.jpg');
    width: 100%;
    max-width: 1220px;
    margin: auto;
    border-radius: 10px;
    height: 374px;
}

.aboutMaincontainer .bannerWorkWithUS .max-container-services.r h1.titleBanner {
    width: 100%;
    text-transform: none;
    max-width: 590px;
    margin-left: 104px;
    font-family: Manrope;
    font-size: 38px;
    font-weight: 700;
    line-height: 51.91px;
    text-align: left;
}

/* Estilos para .tetxBlack */
.textBlack.aboutUS {
    margin: auto;
    margin-top: 65px;
    margin-bottom: 85px;
    font-family: Manrope;
    font-size: 28px;
    font-weight: 400;
    line-height: 38.25px;
    text-align: center;
    max-width: 1012px;
}

.aboutMaincontainer .aboutUSDescriptionContainer {
    display: flex;
    gap: 25px;
    width: 100%;
    margin: auto;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 85px;
    max-width: 1220px;
    align-items: center;
}

.aboutMaincontainer .aboutUSDescriptionContainer .textContainer {
    width: 100%;
    max-width: 597px;
}

.aboutMaincontainer .aboutUSDescriptionContainer .textContainer p {
    font-family: Manrope;
    font-size: 24px;
    font-weight: 400;
    line-height: 32.78px;
    text-align: justified;
}

.aboutMaincontainer .ourValuesAboutUs {
    margin: auto;
    flex-wrap: wrap;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 1220px;

}

.aboutMaincontainer .ourValuesAboutUs .mainTitleComponentSection h1.title {
    margin: 0;
    margin-top: 67px;
    margin-bottom: 35px;
}

.aboutMaincontainer .ourValuesAboutUs .ourValuesMain {
    display: flex;
    justify-content: center;
    align-items: center;
}

.aboutMaincontainer .ourValuesAboutUs .ourValuesMain img {
    margin: 25px;
    margin-top: 0;
    width: 100%;
    max-width: 600px;
}

.aboutMaincontainer .ourValuesAboutUs p {
    box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    width: 287px;
    font-family: Manrope;
    font-size: 28px;
    font-weight: 700;
    line-height: 38.25px;
    text-align: left;
}

.aboutMaincontainer .teamMembers {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 24px;
    width: 100%;
    margin: auto;
    max-width: 1220px;
}

.aboutMaincontainer .teamMembers .memberContainer {
    box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    position: relative;
    align-self: center;
    flex-direction: column;
    width: 598px;
    min-height: 880px;
    padding: 20px;
    gap: 20px;
    border-radius: 10px;
}

.aboutMaincontainer .teamMembers .memberContainer .imageContainer {
    background: linear-gradient(180deg, #0F81FF 0%, #001D3D 100%);
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    height: 100%;
    max-height: 387px;
    width: 100%;
    max-width: 387px;
}

.aboutMaincontainer .teamMembers .memberContainer .imageContainer img {
    height: 388px;
    width: 388px;
}

.aboutMaincontainer .teamMembers .memberContainer .imageContainer::after {
    position: absolute;
    cursor: pointer;
    content: "";
    top: 12px;
    height: 100%;
    max-height: 397px;
    width: 100%;
    max-width: 397px;
    background-color: rgba(255, 255, 255, 0);
    border-radius: 100%;
    border: 3px solid rgba(6, 214, 160, 1);
}

.aboutMaincontainer .teamMembers .memberContainer .infoContainer {
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
    flex-direction: column;
}

.aboutMaincontainer .teamMembers .memberContainer .infoContainer p {
    margin: 0;
}

.aboutMaincontainer .teamMembers .memberContainer .infoContainer .memberName {
    font-family: Manrope;
    font-size: 38px;
    font-weight: 700;
    line-height: 51.91px;
    text-align: center;
}

.aboutMaincontainer .teamMembers .memberContainer .infoContainer .memberPosition {
    font-family: Manrope;
    font-size: 28px;
    font-weight: 700;
    line-height: 38.25px;
    text-align: center;
    color: rgba(6, 214, 160, 1);
    max-width: 298px;
}

.aboutMaincontainer .teamMembers .memberContainer .infoContainer .memberDescription {
    font-family: Manrope;
    font-size: 20px;
    font-weight: 400;
    line-height: 27.32px;
    text-align: justified;
}

.aboutMaincontainer .teamMembers .memberContainer .infoContainer .memberDescription span {
    font-weight: 700;
}

/*Tablet*/
@media (max-width: 1230px) {
    .aboutMaincontainer .ourValuesAboutUs .ourValuesMain {
        flex-direction: column;
    }
}

@media (min-width: 702px) and (max-width: 910px) {

    .teamCardContainer .imageContainer {
        height: 100%;
    }

    .teamCardContainer .imageContainer img {
        height: 75%;
    }

    .teamCardContainer .textContainer .mainTitle {
        font-size: 20px;
        line-height: unset;
    }

    .teamCardContainer .textContainer .logo {
        position: unset;
    }

    .aboutMaincontainer .roofsContainer .targetComponentContainer .mainTitle {
        font-size: 23px;
    }
}

/* TELEFONO */
@media (max-width: 470px) {
    .aboutMaincontainer .teamMembers .memberContainer .borderContainer {
        padding: 5px;
        border-radius: 50%;
        border: 3px solid rgba(6, 214, 160, 1);
    }

    .aboutMaincontainer .teamMembers .memberContainer .imageContainer::after {
        position: absolute;
        cursor: pointer;
        content: "";
        top: 12px;
        height: 100%;
        max-height: 240px;
        width: 100%;
        max-width: 240px;
        background-color: rgba(255, 255, 255, 0);
        border-radius: 100%;
        border: 0px solid rgba(6, 214, 160, 1);
    }
}

@media (min-width: 300px) and (max-width: 700px) {

    .teamCardContainer {
        flex-direction: column;
        align-items: center;
    }

    .teamCardContainer .imageContainer img,
    .teamCardContainer .textContainer img {
        width: 100%;
    }

    .teamCardContainer .textContainer .logo {
        position: static;
        margin-top: 10px;
    }

    .image-container {
        width: 90%;
    }

    .about-us-image {
        height: 20vh;
    }

    .overlay-text {
        font-size: 18px;
        bottom: 60%;
        left: 12%;
        max-width: 75%;
        text-align: left;
    }

    .mainTitleComponentSection h1.title {
        font-size: 25px;
    }

    .tetxBlack {
        font-size: 17px;
    }

    .teamCardContainer .textContainer .mainTitle {
        font-size: 25px;
        line-height: unset;
    }

    .aboutMaincontainer .roofsContainer .targetComponentContainer .mainTitle {
        font-size: 23px;
    }

    .aboutMaincontainer .roofsContainer .targetComponentContainer,
    .aboutMaincontainer .roofsContainer .targetComponentContainer.second {
        flex-direction: column;
        height: auto;
        max-height: 1100%;
    }

    .aboutMaincontainer .roofsContainer .targetComponentContainer img {
        width: 100%;
    }

    .aboutMaincontainer .aboutUSDescriptionContainer img,
    .aboutMaincontainer .ourValuesAboutUs .ourValuesMain img {
        width: 100%;
    }

    .aboutMaincontainer .bannerWorkWithUS .max-container-services.r h1.titleBanner {
        margin-left: auto;
    }

    .aboutMaincontainer .teamMembers .memberContainer .imageContainer img {
        height: 100%;
        width: 100%;
    }

    .aboutMaincontainer .teamMembers .memberContainer {
        max-height: fit-content;
    }

}