.blogMainContainer .blogMainBanner {
    background-image: linear-gradient(rgba(0, 29, 61, 0.4), rgba(0, 53, 102, 0.4)),
        url("../../../../public/img/electricity-flowing-smooth-blue-abstract-wave-generated-by-ai 1.jpg");
    height: 268px;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
}

.blogMainContainer .blogMainBanner .title {
    color: #ffffff;
    max-width: 67%;
    font-size: 52px;
}

.blogMainContainer .featuredArticles,
.blogMainContainer .LatestNews {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 25px;
    max-width: 1400px;
    margin: 35px auto 50px auto;
}

.blogMainContainer .articleCardContainer {
    max-width: 392px;
}

.blogMainContainer .articleCardContainer .button {
    max-width: 100%;
}

.blogMainContainer .articleCardContainer {
    max-width: 392px;
    height: 510px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 12px 15px 12px 15px;
    border-radius: 10px;
    box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.25);
}

.articleCardContainer div h2 {
    font-size: 20px;
}

.articleCardContainer div p {
    font-size: 15px;
}

.blogMainContainer .articleCardContainer h2.titleH4Bold {
    margin: 15px 0;
}

.blogMainContainer .articleCardContainer p {
    margin: 15px 0;
}

.articleCardContainer .mediumText {
    color: rgba(82, 82, 82, 1);
}

.blogMainContainer .pagination {
    display: flex;
    width: fit-content;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    gap: 10px;
    box-shadow: 3px 3px 10px 0px #00000040;
    border-radius: 10px;
    padding: 8px;
}

.blogMainContainer .pagination .arrow {
    cursor: pointer;
}

.blogMainContainer .pagination button {
    background: #ffffff;
    border: none;
    width: 31px;
    height: 32px;
    border-radius: 3px;
    font-family: Manrope;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
}

.blogMainContainer .pagination button.active {
    background: #06D6A0;
    color: #ffffff;
}

.articleCardContainer div {
    word-wrap: break-word;
}

.articleCardContainer img {
    width: 362px;
    height: 268px;
    border-radius: 10px;
}

/*Tablet*/
@media (min-width: 700px) and (max-width: 910px) {
    .blogMainContainer .blogMainBanner .title {
        max-width: 90%;
        font-size: 35px;
    }

    .blogMainContainer .blogMainBanner {
        height: 20vh;
    }

    .blogMainContainer .articleCardContainer {
        width: 46vw;
        height: 50vh;
    }

    .articleCardContainer div img {
        width: 100%;
    }

    .articleCardContainer div h2 {
        font-size: 18px;
    }

    .articleCardContainer div p {
        font-size: 15px;
    }

}

/*Telefono*/
@media (min-width: 320px) and (max-width: 702px) {
    .blogMainContainer .blogMainBanner .title {
        max-width: 90%;
        font-size: 25px;
        line-height: unset;
    }

    .blogMainContainer .blogMainBanner {
        height: 30vh;
    }

    .articleCardContainer div img {
        width: 100%;
        display: none;
    }

    .blogMainContainer .articleCardContainer {
        height: fit-content;
        width: 85%;
    }

    .articleCardContainer div h2 {
        font-size: 20px;
    }

    .articleCardContainer div p {
        font-size: 15px;
    }

}