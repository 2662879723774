.positionsApplySection {
    margin-bottom: 100px;
    max-width: 1100px;
    margin: auto;
}

.positionsApplySection .positionsApplyContainer {
    height: 163px;
}

.positionsApplySection .positionsApplyContainer .positionsApplyBackground {
    background-color: rgba(0, 29, 61, 0.4);
    position: relative;
    top: -166px;
    height: 163px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.positionsApplySection .positionsApplyContainer .positionsApplyBackground .positionsApplyText {
    font-family: Manrope;
    font-size: 24px;
    font-weight: 700;
    line-height: 32.78px;
    text-align: center;
    color: #fff;
    width: 160px;
}

.positionsApplySection .titleH2{
    margin-bottom: 25px;
}