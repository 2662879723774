.mainTitleComponentSection {
    display: flex;
    flex-direction: column;
}
.mainTitleComponentSection {
    display: flex;
    justify-content: center; 
    align-items: center;    
    text-align: center;    
}

.mainTitleComponentSection h1.title {
    display: flex;
    text-align: center;
    justify-content: center; 
    margin-top: 30px; 
    font-family: Manrope;
    font-size: 52px;
    line-height: 71.03px;
    font-weight: 700;
    max-width: 859px;
}

.mainTitleComponentSection p.mainTextTitle {
    font-family: Manrope;
    font-size: 28px;
    font-weight: 400;
    max-width: 859px;
    margin: 30px auto;
    text-align: center;
}

.mainTitleComponentSection h2 {
    font-family: Manrope;
    font-size: 38px;
    font-weight: 700;
    margin: 30px;
    text-align: center;
}

.mainTitleComponentSection h1.title.small-title {
    font-size: 38px;
    line-height: 51.91px;
}

/*Tablet*/
@media (min-width: 700px) and (max-width: 910px) {
    
    .mainTitleComponentSection p.mainTextTitle {
        font-size: 25px;
        padding: 0 20px;
    }
    .mainTitleComponentSection h1.title {
        font-size: 35px;
    }
    
}
/*Telefono*/
@media (min-width: 320px) and (max-width: 702px) {
   
    .mainTitleComponentSection p.mainTextTitle {
        font-size: 20px;
        padding: 0 17px;
        
    }
    .mainTitleComponentSection h1.title {
        line-height: unset;
    }
}