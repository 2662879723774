.contractorsMainContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 125px;
}

.contractorsMainContainer h1.title {
    font-family: Manrope;
    margin: 35px auto;
    margin-top: 45px;
    font-size: 52px;
    font-weight: 700;
    line-height: 71.03px;
    text-align: center;
}

.contractorsMainContainer p.textMainContractor {
    font-family: Manrope;
    font-size: 28px;
    font-weight: 400;
    line-height: 38.25px;
    text-align: center;
    max-width: 1012px;
    margin: 0 auto;
    margin-bottom: 85px;
}

.contractorsMainContainer button.button {
    width: 330px;
    margin: 30px auto;
}

.contractorsMainContainer button.button.buttonContactContractors {
    margin-bottom: 0;
}

.max-container-services.r button.button {
    margin-left: 71px;
}

.contractorsMainContainer .services-portfolio {
    margin-top: 0;
    margin-bottom: 65px;
    max-width: 1220px;
}

.contractorsMainContainer .services-portfolio-height h2 {
    color: #fff;
    margin: 0;
    font-family: Manrope;
    font-size: 28px;
    font-weight: 700;
    text-align: left;
}

.contractorsMainContainer .roofsContainer .targetComponentContainer img {
    border-radius: 10px;
}

.contractorsMainContainer .services-portfolio-height.consulting {
    background-image: linear-gradient(rgba(0, 29, 61, 0.4), rgba(0, 53, 102, 0.4)),
        url("../../../public/img/business-person-futuristic-business-environment 2.jpg");
    background-size: cover;
    border-radius: 10px;
    padding-bottom: 88px;
    min-height: 374px;
}

.contractorsMainContainer .services-portfolio-height h1.titleBanner,
.contractorsMainContainer .services-portfolio-height .max-container-services.l {
    max-width: 520px;
    text-transform: capitalize;
    font-family: Manrope;
    font-size: 38px;
    font-weight: 700;
    line-height: 51.91px;
    text-align: left;

}

.contractorsMainContainer .services-portfolio-height .max-container-services.l {
    margin-left: 7%;
}

.contractorsMainContainer .services-portfolio-height .max-container-services.l button.button {
    margin-left: 0;
}

.contractorsMainContainer .max-container-services.r button.button {
    margin-left: 0;
    width: 100%;
}

.contractorsMainContainer .contractorsData.targetsSection {
    flex-wrap: wrap;
}

.contractorsMainContainer .targetsSection .targetsContainer {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 85px;
    width: 100%;
    max-width: 1400px;
}

.contractorsMainContainer .targetsSection .targetsContainer .targetHomeContainer {
    width: 100%;
    max-width: 598px;
    height: min-content;
    padding: 0;
    margin-bottom: 0;
}

.contractorsMainContainer .targetsSection .targetsContainer .targetHomeContainer h2 {
    max-width: 404px;
    margin: 15px 20px 15px 20px;
    padding: 0;
}

.contractorsMainContainer .targetsSection .targetsContainer .targetHomeContainer p {
    font-family: Manrope;
    font-size: 17px;
    font-weight: 500;
    line-height: 23.22px;
    text-align: left;
}

.contractorsMainContainer .targetsSection .targetsContainer .targetHomeContainer img {
    width: 100%;
    max-width: 598px;
}

.contractorsMainContainer .targetsSection .targetsContainer .targetInfoContainer {
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-bottom: 0;
    justify-content: space-between;
    height: 220px;
}

.contractorsMainContainer .professionalsSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contractorsMainContainer .professionalsSection .professionalsImageContractors {
    background-image: url("../../../public/img/business team 1.jpg");
    height: 290px;
    width: 100%;
    max-width: 1220px;
    border-radius: 10px;
}

.contractorsMainContainer .professionalsSection .professionalsText {
    width: 100%;
    max-width: 1220px;
}

.contractorsMainContainer .professionalsSection .professionalsText .textH4 {
    text-align: left;
}

.contractorsMainContainer .professionalsSection .professionalsText .textH4:nth-child(2) {
    margin-bottom: 0;
}

.contractorsMainContainer .professionalsSection .teamList {
    display: flex;
    width: 100%;
    max-width: 1220px;
    flex-wrap: wrap;
    justify-content: space-between;
}

.contractorsMainContainer .professionalsSection .titleH2 {
    margin: 35px auto;
}

.contractorsMainContainer .benefitsCardsComponentSection .cardsContainer {
    width: 100%;
    max-width: 1220px;
    gap: 24px;
}

.contractorsMainContainer .benefitsCardsComponentSection .benfitContainer {
    width: 100%;
    max-width: 390px;
    min-height: 214px;
}

.contractorsMainContainer .benefitsCardsComponentSection p.titleText {
    width: 83%;
}

.contractorsMainContainer .benefitsCardsComponentSection .cardsContainer .benfitContainer .secondaryText ul li {
    font-family: Manrope;
    font-size: 18px;
    font-weight: 500;
    line-height: 27.32px;
    text-align: left;
}

.contractorsMainContainer .benefitsCardsComponentSection .cardsContainer .benfitContainer .secondaryText ul {
    margin: 0;
}

.contractorsMainContainer .benefitsCardsComponentSection p.secondaryText {
    margin: 0;
}

.contractorsMainContainer .benefitsCardsComponentSection .benfitContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.contractorsMainContainer .servicesSection .ServicesContainer {
    max-width: 1220px;
    margin-bottom: 35px;
}

.contractorsMainContainer .servicesSection .ServicesContainer .cardServiceContainer {
    width: 287px;
}

.contractorsMainContainer .servicesSection .ServicesContainer .cardServiceContainer .cardTitleContainer {
    align-items: center;
}

.contractorsMainContainer .servicesSection .ServicesContainer .cardServiceContainer .cardTitleContainer .imgCardService {
    min-width: 52px;
    min-height: 52px;
    margin-bottom: 10px;
}

.contractorsMainContainer .ServicesContainer h2 {
    font-family: Manrope;
    font-size: 20px;
    font-weight: 700;
    line-height: 27.32px;
    text-align: left;
    margin-bottom: 0;
}

.contractorsMainContainer .benefitsCardsComponentSection .title {
    margin-top: 85px;
    margin-bottom: 35px;
}

.contractorsMainContainer h1.title.benefits {
    margin-top: 85px;
    margin-bottom: 35px;
}

.contractorsMainContainer .servicesSection .ServicesContainer .cardServiceContainer .cardTitleContainer .imgCardService img {
    width: 30px;
}

.contractorsMainContainer .targetsSection .targetsContainer .targetHomeContainer:nth-child(3) {
    background-color: #06D6A0;
}

.contractorsMainContainer .targetsSection .targetsContainer .targetHomeContainer:nth-child(3),
.contractorsMainContainer .targetsSection .targetsContainer .targetHomeContainer:nth-child(3) h2 {
    color: #000000;
}

/*Tablet*/
@media (min-width: 700px) and (max-width: 910px) {}

/*Telefono*/
@media (min-width: 320px) and (max-width: 702px) {
    .contractorsMainContainer button.button {
        margin-top: 30px;
        margin: unset auto;
        width: 100%;
    }

    .targetComponentContainer img {
        height: 25vh;
        width: 100%;
    }
}

@media (min-width: 320px) and (max-width: 1235px) {
    .contractorsMainContainer button.button {
        margin-top: 30px;
        margin: unset auto;
        width: 100%;
    }

    .targetComponentContainer img {
        height: 25vh;
        width: 100%;
    }

    /* .contractorsMainContainer .professionalsSection .teamList {
        flex-direction: column;
    } */
    .contractorsMainContainer .benefitsCardsComponentSection .cardsContainer,
    .contractorsMainContainer .professionalsSection {
        margin: 0 15px;
        width: auto;
    }
}