[type="radio"] {
    opacity: 0;
    position: relative;
    z-index: 10;
    cursor: pointer;
}

[type="radio"]+label {
    position: relative;
    padding-left: 8px;
    cursor: pointer;
    display: inline-block;
    font-family: Manrope;
    font-size: 15px;
    font-weight: 700;
    text-align: left;
}

[type="radio"]+label::before {
    position: absolute;
    cursor: pointer;
    content: "";
    left: -20px;
    top: 0;
    width: 16px;
    height: 16px;
    background-color: rgba(255, 255, 255, 0);
    border-radius: 100%;
    border: 2px solid #AAAAAA;
}

[type="radio"]+label::after {
    position: absolute;
    content: "";
    cursor: pointer;
    left: -16px;
    top: 4px;
    width: 12px;
    height: 12px;
    background-color: #00000000;
    border-radius: 100%;
}

[type="radio"]:checked+label::after {
    background-color: rgb(0, 255, 30);
}

[type="radio"]:checked+label::before {
    border: 2px solid rgb(0, 255, 30);
    animation: pulse-shadow 0.6s ease forwards;
}

/* Definición de la animación */
@keyframes pulse-shadow {
    0% {
        box-shadow: 0px 0px 0px 0px rgba(6, 214, 160, 0.1);
    }
    50% {
        box-shadow: 0px 0px 0px 20px rgba(6, 214, 160, 0.1);
    }
    100% {
        box-shadow: 0px 0px 0px 0px rgba(6, 214, 160, 0.1);
    }
}
